import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'

import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import { storage } from '../firebase'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { FaArrowLeft, FaTrash, FaCheckCircle } from 'react-icons/fa' // Import FaCheckCircle icon
import Compressor from 'compressorjs'
import JSZip from 'jszip'

const DownloadGamesCover_Admin = () => {
	const [gameTitles, setGameTitles] = useState([]) // State untuk menyimpan daftar judul game
	const [gameImages, setGameImages] = useState([]) // State untuk menyimpan daftar semua cover game
	const { isDarkMode } = useDarkModeContext()
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		// Fetch list of game titles from database
		const fetchData = async () => {
			try {
				const snapshot = await fireDb.ref(`vrGames/standalone`).once('value')
				const data = snapshot.val()
				if (data) {
					const titles = Object.keys(data).map((key) => ({
						id: key,
						title: data[key].gameTitle,
					}))
					setGameTitles(titles)
				}
			} catch (error) {
				console.error('Error fetching game titles:', error)
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		const fetchGameImages = async () => {
			try {
				const gameImageRef = ref(storage, 'coverGamesImages')
				const gameImageList = await listAll(gameImageRef)
				const images = await Promise.all(
					gameImageList.items.map(async (item) => {
						const url = await getDownloadURL(item)
						return { name: item.name, url }
					})
				)
				setGameImages(images)
			} catch (error) {
				console.error('Error fetching game images:', error)
			}
		}
		fetchGameImages()
	}, [])

	const extractGameName = (fileName) => {
		// Misalnya, Anda ingin menggunakan bagian nama berkas sebelum garis bawah (_) sebagai nama game
		const parts = fileName.split('_') // Membagi nama berkas menjadi bagian-bagian berdasarkan garis bawah (_)
		const gameName = parts[0] // Mengambil bagian pertama sebagai nama game
		return gameName
	}

	const handleDownloadAll = () => {
		const zip = new JSZip()
		const promises = []
		const currentDate = new Date()
		const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}_${currentDate.getHours().toString().padStart(2, '0')}-${currentDate.getMinutes().toString().padStart(2, '0')}-${currentDate.getSeconds().toString().padStart(2, '0')}`
		gameImages.forEach((image, index) => {
			const promise = fetch(image.url)
				.then((response) => response.blob())
				.then((blob) => {
					const gameName = extractGameName(image.name)
					zip.file(`${gameName}.png`, blob)
				})
			promises.push(promise)
		})
		Promise.all(promises).then(() => {
			zip.generateAsync({ type: 'blob' }).then((blob) => {
				const url = URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `All_Games_Cover_${formattedDate}.zip`)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			})
		})
	}

	return (
		<div
			className={`pt-6 mb-10 mx-5 w-full h-full ${!window.innerWidth < 1200 && 'pt-[80px]'} min-h-screen`}
		>
			<div className="flex flex-col items-center justify-center">
				<div
					className={`py-6 px-6 m-auto w-full flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'} shadow-lg`}
				>
					<div className="flex items-center justify-between border-b pb-4">
						<h2 className="text-2xl font-bold">Download All Covers</h2>
						<button
							onClick={handleDownloadAll}
							className="bg-gradient-to-r from-blue-500 to-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg hover:shadow-xl transition duration-300 ease-in-out"
						>
							Download All
						</button>
					</div>

					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
						{Array.isArray(gameImages) &&
							gameImages.map((image, index) => (
								<div
									key={index}
									className="border rounded-lg bg-gray-100 transition-transform transform hover:scale-105"
								>
									<div className="relative">
										<img
											src={image.url}
											alt={`Cover ${image.name}`}
											className="w-full h-full rounded-t-lg object-cover"
											style={{ aspectRatio: '32 / 9' }} // Ensure the image is square
										/>
										<div className="absolute top-4 right-4">
											<a
												href={image.url}
												download={`cover_${index}`}
												className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded shadow"
											>
												Download
											</a>
										</div>
									</div>
									<div className="p-4 text-center">
										<span className="text-sm font-semibold">{image.name}</span>
									</div>
								</div>
							))}
					</div>
				</div>

				<Helmet>
					<title>Download All Covers | Admin</title>
				</Helmet>
			</div>
		</div>
	)
}

export default DownloadGamesCover_Admin
