import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'

import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import { storage } from '../firebase'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { FaArrowLeft, FaCheckCircle, FaTrash } from 'react-icons/fa'
import Compressor from 'compressorjs'
import { FaPlus } from 'react-icons/fa6'
import toast, { Toaster } from 'react-hot-toast'

const initialState_VRGames = {
	newAdded: false,
	dataType: 'vrGames',
	gameTitle: '',
	linkMetaStore: '',
	linkDownload: '',
	gameVersion: 'v',
	downloadCount: 0,
	likedCount: 0,
	gameSize: '',
	releaseDate: new Date().toISOString().split('T')[0],
	gameStatus: 'new',
	mixedReality: 'no',
	supportMetaQuest3: true,
	supportMetaQuestPro: true,
	supportMetaQuest2: true,
	supportMetaQuest1: true,
	supportMetaRift: true,
	supportMetaRiftS: true,
	photoUrl: '',
	imageUrl: '', // Menyimpan URL gambar yang akan diunduh
	compressedImage: null, // Menyimpan gambar yang telah dikompresi
	videoIdYouTube: '',
	recommendedProcessor: '',
	recommendedGraphicsCard: '',
	recommendedMemory: '',
	timeAdded: new Date().toISOString(),
}

const AddEdit_PCVRGames = () => {
	const [userTypeAndGames, setUserTypeAndGames] = useState('standalone') // Default to 'standalone'

	const [state_VRGames, setState_VRGames] = useState(initialState_VRGames)

	const [imageLink, setImageLink] = useState('') // State untuk menyimpan URL gambar dari input

	const { isDarkMode } = useDarkModeContext()
	const [data, setData] = useState({})
	const navigate = useNavigate()
	const { id } = useParams()

	const imageListRef = ref(storage, 'images/')

	const language = localStorage.getItem('language') || 'id'

	const [dataType, setDataType] = useState(
		localStorage.getItem('dataTypeAddEdit')
	) // Default to 'vrGames'

	const [dataAddGameType, setDataAddGameType] = useState(
		localStorage.getItem('dataAddGameType')
	) // Default to 'standalone'

	const [gameImageUrl, setGameImageUrl] = useState(null)

	const [isGameImageAvailable, setIsGameImageAvailable] = useState(false)

	useEffect(() => {
		const checkGameImageAvailability = async () => {
			try {
				if (state_VRGames.gameTitle) {
					const gameImageRef = ref(storage, 'coverGamesImages')
					const gameImageList = await listAll(gameImageRef)
					const gameImageExists = gameImageList.items.some(
						(item) => item.name === state_VRGames.gameTitle
					)
					setIsGameImageAvailable(gameImageExists)
				} else {
					setIsGameImageAvailable(false)
				}
			} catch (error) {
				console.error('Error checking game image availability:', error)
				setIsGameImageAvailable(false)
			}
		}
		checkGameImageAvailability()
	}, [state_VRGames.gameTitle])

	useEffect(() => {
		if (id) {
			fireDb.ref(`vrGames/pcvr/${id}`).on('value', (snapshot) => {
				const existingData = snapshot.val()
				if (existingData) {
					setState_VRGames({
						newAdded: existingData.newAdded || false,
						gameTitle: existingData.gameTitle || '',
						linkMetaStore: existingData.linkMetaStore || '',
						linkDownload: existingData.linkDownload || '',
						downloadLinks: existingData.linkDownload
							? existingData.linkDownload.split('\n')
							: [''],
						gameVersion: existingData.gameVersion || '',
						downloadCount: existingData.downloadCount || 0,
						likedCount: existingData.likedCount || 0,
						gameSize: existingData.gameSize || '',
						releaseDate:
							existingData.releaseDate ||
							new Date().toISOString().split('T')[0],
						gameStatus: existingData.gameStatus || 'new',
						mixedReality: existingData.mixedReality || 'no',
						supportMetaQuest3: existingData.supportMetaQuest3 || false,
						supportMetaQuestPro: existingData.supportMetaQuestPro || false,
						supportMetaQuest2: existingData.supportMetaQuest2 || false,
						supportMetaQuest1: existingData.supportMetaQuest1 || false,
						supportMetaRift: existingData.supportMetaRift || false,
						supportMetaRiftS: existingData.supportMetaRiftS || false,
						photoUrl: existingData.photoUrl || '',
						videoIdYouTube: existingData.videoIdYouTube || '',
						recommendedProcessor: existingData.recommendedProcessor || '',
						recommendedGraphicsCard: existingData.recommendedGraphicsCard || '',
						recommendedMemory: existingData.recommendedMemory || '',
						timeAdded:
							existingData.gameStatus === 'nothing'
								? existingData.timeAdded
								: new Date().toISOString(),
					})
				} else {
					setState_VRGames(initialState_VRGames)
				}
			})
		} else {
			setState_VRGames({
				...initialState_VRGames,
				downloadLinks: [''],
				timeAdded: new Date().toISOString(),
			})
		}

		return () => {
			fireDb.ref(`vrGames/pcvr/${id}`).off('value')
		}
	}, [id, dataType])

	useEffect(() => {
		setState_VRGames((prevState) => ({
			...prevState,
			supportMetaQuest3: prevState.supportMetaQuest3 || false,
			supportMetaQuestPro: prevState.supportMetaQuestPro || false,
			supportMetaQuest2: prevState.supportMetaQuest2 || false,
			supportMetaQuest1: prevState.supportMetaQuest1 || false,
			supportMetaRift: prevState.supportMetaRift || false,
			supportMetaRiftS: prevState.supportMetaRiftS || false,
		}))
	}, [
		state_VRGames.supportMetaQuest3,
		state_VRGames.supportMetaQuestPro,
		state_VRGames.supportMetaQuest2,
		state_VRGames.supportMetaQuest1,
		state_VRGames.supportMetaRift,
		state_VRGames.supportMetaRiftS,
	])

	const handleInputChange_VRGames = (e) => {
		const { name, value, type, checked } = e.target
		const inputValue = type === 'checkbox' ? checked : value

		if (name === 'linkDownload') {
			// Jika name adalah linkDownload, perbarui state downloadLinks
			setDownloadLinks((prevLinks) => [...prevLinks, inputValue])
		} else {
			// Jika bukan linkDownload, perbarui state state_VRGames seperti sebelumnya
			setState_VRGames({ ...state_VRGames, [name]: inputValue })
		}
	}

	const handleImageLinkChange = (e) => {
		// Mendapatkan nilai input dan membersihkannya dari "url(" dan ")"
		const cleanedUrl = cleanImageUrl(e.target.value)
		setImageLink(cleanedUrl)
	}

	const handleDataAddTypeGame = (e) => {
		setDataAddGameType(e.target.value)
		localStorage.setItem('dataAddGameType', e.target.value)
		setUserTypeAndGames(localStorage.getItem('dataAddGameType'))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!state_VRGames.gameTitle) {
			toast.error('Please provide a value in each input field!')
		} else {
			const timeAddedValue =
				state_VRGames.gameStatus !== 'nothing'
					? new Date().toISOString()
					: state_VRGames.timeAdded
			const gameData = {
				newAdded: state_VRGames.newAdded,
				dataType: 'vrGames',
				gameTitle: state_VRGames.gameTitle,
				linkMetaStore: state_VRGames.linkMetaStore,
				linkDownload: downloadLinks.join('\n'),
				gameVersion: state_VRGames.gameVersion,
				downloadCount: parseInt(state_VRGames.downloadCount),
				likedCount: parseInt(state_VRGames.likedCount),
				gameSize: state_VRGames.gameSize,
				releaseDate: state_VRGames.releaseDate,
				supportMetaQuest3: state_VRGames.supportMetaQuest3,
				supportMetaQuestPro: state_VRGames.supportMetaQuestPro,
				supportMetaQuest2: state_VRGames.supportMetaQuest2,
				supportMetaQuest1: state_VRGames.supportMetaQuest1,
				supportMetaRift: state_VRGames.supportMetaRift,
				supportMetaRiftS: state_VRGames.supportMetaRiftS,
				gameStatus: state_VRGames.gameStatus,
				mixedReality: state_VRGames.mixedReality,
				photoUrl: state_VRGames.photoUrl,
				videoIdYouTube: state_VRGames.videoIdYouTube,
				recommendedProcessor: state_VRGames.recommendedProcessor,
				recommendedGraphicsCard: state_VRGames.recommendedGraphicsCard,
				recommendedMemory: state_VRGames.recommendedMemory,
				timeAdded: timeAddedValue,
			}

			if (!id) {
				fireDb
					.ref(`vrGames/pcvr/${state_VRGames.gameTitle}`)
					.set(gameData, (err) => {
						if (err) {
							toast.error(err)
						} else {
							toast.success('Game Added Successfully!')
						}
					})
			} else {
				fireDb.ref(`vrGames/pcvr/${id}`).set(gameData, (err) => {
					if (err) {
						toast.error(err)
					} else {
						toast.success('Game Updated Successfully!')
					}
				})
			}

			setTimeout(() => navigate('/list-vr-games', { replace: true }), 500)
		}
	}

	useEffect(() => {
		listAll(imageListRef).then((response) => {
			const gameImageName = `${state_VRGames.gameTitle}`
			const userImage = response.items.find(
				(item) => item.name === gameImageName
			)
			if (userImage) {
				getDownloadURL(userImage).then((url) => {
					setGameImageUrl(url)
					setImageLink(url)
				})
			}
		})
	}, [state_VRGames.gameTitle, imageListRef])

	const uploadImage_VRGames = () => {
		if (imageLink) {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', imageLink)
			xhr.responseType = 'blob'
			xhr.onload = () => {
				const blob = xhr.response
				const fileName = state_VRGames.gameTitle // Gunakan gameTitle tanpa mengubah apapun
				new Compressor(blob, {
					quality: 0.7,
					maxWidth: 650,
					success(compressedBlob) {
						const imageRef_VRGames = ref(
							storage,
							`coverGamesImages/${fileName}`
						)
						uploadBytes(imageRef_VRGames, compressedBlob).then(() => {
							toast.success('Image uploaded successfully!')
						})
						getDownloadURL(imageRef_VRGames).then((url) => {
							setState_VRGames({ ...state_VRGames, photoUrl: url })
						})
					},
					error(error) {
						console.error('Error compressing image:', error)
						toast.error('Failed to compress image.')
					},
				})
			}
			xhr.send()
		}
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	const [downloadLinks, setDownloadLinks] = useState(
		state_VRGames.linkDownload ? state_VRGames.linkDownload.split('\n') : ['']
	)

	const handleAddDownloadLink = () => {
		setDownloadLinks([...downloadLinks, ''])
	}

	const handleRemoveDownloadLink = (index) => {
		const updatedLinks = [...downloadLinks]
		updatedLinks.splice(index, 1)
		setDownloadLinks(updatedLinks)
	}

	const handleDownloadLinkChange = (index, e) => {
		const updatedLinks = [...downloadLinks]
		updatedLinks[index] = e.target.value
		setDownloadLinks(updatedLinks)
	}

	const cleanImageUrl = (imageUrl) => {
		// Hapus "url(" dari awal string dan ")" dari akhir string
		return imageUrl.replace(/^url\("(.*)"\)$/, '$1')
	}

	// Set download links from database to state
	useEffect(() => {
		if (state_VRGames.linkDownload) {
			const linksArray = state_VRGames.linkDownload.split('\n')
			setDownloadLinks(linksArray)
		}
	}, [state_VRGames.linkDownload])

	return (
		<div className="mx-5 pt-6 mb-10 w-full">
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'}`}
					onSubmit={handleSubmit}
				>
					<Link
						to="/list-vr-games"
						className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
					>
						<FaArrowLeft className="mr-2" />
						Back to List VR Games
					</Link>

					<hr className="border-[1px] border-gray-300 rounded" />
					<>
						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="gameTitle">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Game Title
								</span>
							</label>
							<input
								type="text"
								id="gameTitle"
								name="gameTitle"
								placeholder="Fill Here"
								value={state_VRGames.gameTitle || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="linkMetaStore">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Link Meta Store
								</span>
							</label>
							<input
								type="url"
								id="linkMetaStore"
								name="linkMetaStore"
								placeholder="Fill Here"
								value={state_VRGames.linkMetaStore || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="linkDownload">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Link Download
								</span>
							</label>
							<div className="flex flex-col gap-2 w-full">
								{downloadLinks.map((link, index) => (
									<div key={index} className="flex gap-2 items-center">
										<input
											type="url"
											id={`linkDownload_${index}`}
											name={`linkDownload_${index}`}
											placeholder="Fill Here"
											value={link || ''} // Set value to the corresponding link in downloadLinks array
											onChange={(e) => handleDownloadLinkChange(index, e)}
											className="rounded p-2 input-box w-full"
										/>
										<button
											type="button"
											onClick={() => handleRemoveDownloadLink(index)}
											className="bg-red-500 text-white p-4 rounded-lg hover:bg-red-700 transition-all duration-300"
										>
											<FaTrash />
										</button>
									</div>
								))}
								<button
									type="button"
									onClick={handleAddDownloadLink}
									className="bg-green-500 text-white px-3 py-2 rounded-lg hover:bg-green-700 transition-all duration-300 w-full flex flex-row gap-2 items-center justify-center"
								>
									Add Download Link
									<FaPlus />
								</button>
							</div>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="gameVersion">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Version
								</span>
							</label>
							<input
								type="text"
								id="gameVersion"
								name="gameVersion"
								placeholder="Fill Here"
								value={state_VRGames.gameVersion || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="downloadCount">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Download Count
								</span>
							</label>
							<input
								type="number"
								id="downloadCount"
								name="downloadCount"
								value={state_VRGames.downloadCount}
								onChange={handleInputChange_VRGames}
								className={`rounded p-2 input-box w-full`}
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="likedCount">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Liked Count
								</span>
							</label>
							<input
								type="number"
								id="likedCount"
								name="likedCount"
								value={state_VRGames.likedCount}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="gameSize">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Game Size
								</span>
							</label>
							<input
								type="text"
								id="gameSize"
								name="gameSize"
								placeholder="Fill Here"
								value={state_VRGames.gameSize || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64">Status:</label>
							<div className="flex gap-2 items-center w-full">
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'new' ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="gameStatus"
										value="new"
										checked={state_VRGames.gameStatus === 'new'}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="newStatusRadio"
									/>
									<label htmlFor="newStatusRadio" className="cursor-pointer">
										New
									</label>
								</div>
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'update' ? 'bg-gradient-to-br from-yellow-300 to-yellow-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="gameStatus"
										value="update"
										checked={state_VRGames.gameStatus === 'update'}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="updateStatusRadio"
									/>
									<label htmlFor="updateStatusRadio" className="cursor-pointer">
										Update
									</label>
								</div>
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'soon' ? 'bg-gradient-to-br from-green-300 to-green-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="gameStatus"
										value="soon"
										checked={state_VRGames.gameStatus === 'soon'}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="soonStatusRadio"
									/>
									<label htmlFor="soonStatusRadio" className="cursor-pointer">
										Coming Soon
									</label>
								</div>
								<div
									className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'nothing' ? 'bg-gradient-to-br from-zinc-300 to-zinc-500 text-white' : 'text-black hover:bg-gray-200'}`}
								>
									<input
										type="radio"
										name="gameStatus"
										value="nothing"
										checked={state_VRGames.gameStatus === 'nothing'}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="nothingStatusRadio"
									/>
									<label
										htmlFor="nothingStatusRadio"
										className="cursor-pointer"
									>
										Nothing
									</label>
								</div>
							</div>
						</div>

						<div className="flex gap-3 items-center justify-between">
							<label className="w-64">Mixed Reality:</label>
							<div className="flex gap-2 items-center">
								<label>
									<input
										type="radio"
										name="mixedReality"
										value="yes"
										checked={state_VRGames.mixedReality === 'yes'}
										onChange={handleInputChange_VRGames}
										className="mr-2"
									/>
									Yes
								</label>
								<label>
									<input
										type="radio"
										name="mixedReality"
										value="no" // Gunakan nilai boolean langsung
										checked={state_VRGames.mixedReality === 'no'}
										onChange={handleInputChange_VRGames}
										className="mr-2"
									/>
									No
								</label>
							</div>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Supported Quest:
								</span>
							</label>
							<div className="flex flex-row gap-2 justify-start w-full">
								<div
									className={`flex gap-2 items-center w-full text-center ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaQuest3"
										checked={state_VRGames.supportMetaQuest3}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaQuest3Checkbox"
									/>
									<label
										htmlFor="metaQuest3Checkbox"
										className={`cursor-pointer flex items-center justify-center text-nowrap border rounded-full w-full text-center px-3 py-1 ${state_VRGames.supportMetaQuest3 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Meta Quest 3
									</label>
								</div>
								<div
									className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaQuestPro"
										checked={state_VRGames.supportMetaQuestPro}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaQuestProCheckbox"
									/>
									<label
										htmlFor="metaQuestProCheckbox"
										className={`cursor-pointer flex items-center text-nowrap justify-center border rounded-full w-full px-3 py-1 ${state_VRGames.supportMetaQuestPro ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Meta Quest Pro
									</label>
								</div>
								<div
									className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaQuest2"
										checked={state_VRGames.supportMetaQuest2}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaQuest2Checkbox"
									/>
									<label
										htmlFor="metaQuest2Checkbox"
										className={`cursor-pointer flex items-center justify-center text-nowrap border rounded-full w-full px-3 py-1 ${state_VRGames.supportMetaQuest2 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Meta Quest 2
									</label>
								</div>
								<div
									className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaQuest1"
										checked={state_VRGames.supportMetaQuest1}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaQuest1Checkbox"
									/>
									<label
										htmlFor="metaQuest1Checkbox"
										className={`cursor-pointer flex items-center justify-center text-nowrap w-full border rounded-full px-3 py-1 ${state_VRGames.supportMetaQuest1 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Meta Quest 1
									</label>
								</div>
								<div
									className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaRift"
										checked={state_VRGames.supportMetaRift}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaRiftCheckbox"
									/>
									<label
										htmlFor="metaRiftCheckbox"
										className={`cursor-pointer flex items-center justify-center text-nowrap w-full border rounded-full px-3 py-1 ${state_VRGames.supportMetaRift ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Oculus Rift
									</label>
								</div>
								<div
									className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
								>
									<input
										type="checkbox"
										name="supportMetaRiftS"
										checked={state_VRGames.supportMetaRiftS}
										onChange={handleInputChange_VRGames}
										className="hidden"
										id="metaRiftSCheckbox"
									/>
									<label
										htmlFor="metaRiftSCheckbox"
										className={`cursor-pointer flex items-center justify-center text-nowrap w-full border rounded-full px-3 py-1 ${state_VRGames.supportMetaRiftS ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
									>
										Oculus Rift S
									</label>
								</div>
							</div>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="imageLink">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Image URL
								</span>
							</label>
							<input
								type="text"
								id="imageLink"
								name="imageLink"
								placeholder="Enter Image URL"
								value={imageLink === '' ? state_VRGames.photoUrl : imageLink}
								onChange={handleImageLinkChange}
								className="rounded p-2 input-box w-full"
							/>
							{isGameImageAvailable && (
								<div className="px-4 py-2 bg-green-500 text-white rounded-lg flex flex-row gap-2 items-center text-center justify-center">
									<FaCheckCircle />
									<span>Gambar Telah Tersedia!</span>
								</div>
							)}
						</div>

						<hr className="border-[1px] border-gray-300 rounded" />
						<span className="text-xl font-semibold">
							Recommended Specification Infomation
						</span>
						<hr className="border-[1px] border-gray-300 rounded" />

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="recommendedProcessor">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Recommended Processor
								</span>
							</label>
							<input
								type="text"
								id="recommendedProcessor"
								name="recommendedProcessor"
								placeholder="Fill Here"
								value={state_VRGames.recommendedProcessor || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="recommendedGraphicsCard">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Recommended Graphics Card
								</span>
							</label>
							<input
								type="text"
								id="recommendedGraphicsCard"
								name="recommendedGraphicsCard"
								placeholder="Fill Here"
								value={state_VRGames.recommendedGraphicsCard || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<div className="flex gap-3 items-center justify-between w-full">
							<label className="w-64" htmlFor="recommendedMemory">
								<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
									Recommended Memory
								</span>
							</label>
							<input
								type="text"
								id="recommendedMemory"
								name="recommendedMemory"
								placeholder="Fill Here"
								value={state_VRGames.recommendedMemory || ''}
								onChange={handleInputChange_VRGames}
								className="rounded p-2 input-box w-full"
							/>
						</div>

						<input
							className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
							type="submit"
							value={id ? 'Update' : 'Save'}
							onClick={uploadImage_VRGames}
						/>
					</>
				</form>

				<Helmet>
					<title>
						{id
							? dataType === 'vrGames'
								? 'Edit VR Games'
								: 'Edit Product'
							: dataType === 'vrGames'
								? 'Add VR Games'
								: 'Add Product'}{' '}
						| Admin
					</title>
				</Helmet>
			</div>

			<style>
				{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default AddEdit_PCVRGames
