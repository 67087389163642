import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'
import { Helmet } from 'react-helmet'
import { FaArrowLeft } from 'react-icons/fa'
import toast, { Toaster } from 'react-hot-toast'

const initialState = {
	orderNumber: '',
	category: '',
	orderName: [],
	quantity: '',
	email: '',
	date: new Date().toISOString(),
	isRedeemed: false,
}

const categories = ['Games VR', 'Akun Steam']
const orderNameOptions = ['Standalone', 'PCVR', 'QGO', 'Lainnya']

const AddEdit_ShopeeOrders = () => {
	const [state, setState] = useState(initialState)
	const [manualOrderName, setManualOrderName] = useState('')
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		if (id) {
			fireDb
				.ref(`shopeeOrders/${id}`)
				.once('value')
				.then((snapshot) => {
					if (snapshot.exists()) {
						setState(snapshot.val())
					}
				})
		} else {
			setState({ ...initialState, date: new Date().toISOString() })
		}
	}, [id])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setState({
			...state,
			[name]: name === 'isRedeemed' ? value === 'true' : value,
		})
	}

	const handleOrderNameChange = (name) => {
		setState((prev) => ({
			...prev,
			orderName: prev.orderName.includes(name)
				? prev.orderName.filter((item) => item !== name)
				: [...prev.orderName, name],
		}))
	}

	const [displayDate, setDisplayDate] = useState(
		new Date(initialState.date).toISOString().slice(0, 19)
	)

	const toWIB = (isoDate) => {
		const date = new Date(isoDate)
		const offsetWIB = 7 * 60 * 60 * 1000 // Offset GMT+7 in milliseconds
		return new Date(date.getTime() + offsetWIB).toISOString().slice(0, 19)
	}

	useEffect(() => {
		if (id) {
			fireDb
				.ref(`shopeeOrders/${id}`)
				.once('value')
				.then((snapshot) => {
					if (snapshot.exists()) {
						const data = snapshot.val()
						setState(data)
						setDisplayDate(toWIB(data.date))
					}
				})
		} else {
			const now = new Date().toISOString()
			setState({ ...initialState, date: now })
			setDisplayDate(toWIB(now))
		}
	}, [id])

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!state.orderNumber || !state.category || !state.quantity) {
			toast.error('Please provide values in the required fields!')
			return
		}

		const orderData = {
			...state,
			date: state.date, // Tetap gunakan format ISO (000Z)
		}

		fireDb
			.ref(`shopeeOrders/${id || state.orderNumber}`)
			.set(orderData, (err) => {
				if (err) {
					toast.error(err.message || 'Error occurred while saving the order.')
				} else {
					toast.success('Order saved successfully!')
					navigate('/list-shopee-orders', { replace: true })
				}
			})
	}

	const formatToWIB = (isoDate) => {
		const date = new Date(isoDate)
		return new Intl.DateTimeFormat('id-ID', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			timeZone: 'Asia/Jakarta',
		}).format(date)
	}

	return (
		<div className="py-10 px-10 w-full">
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className="p-6 m-auto w-full max-w-5xl grid grid-cols-1 md:grid-cols-2 gap-6 rounded-2xl bg-white border-2"
					onSubmit={handleSubmit}
				>
					<h3 className="col-span-1 md:col-span-2 text-xl font-semibold text-center mb-4 bg-[#0081FB] py-2 rounded-xl text-white">
						{id ? 'Edit Pesanan Shopee' : 'Tambah Pesanan Shopee'}
					</h3>

					{/* Order Number */}
					<div className="flex flex-col">
						<label
							htmlFor="orderNumber"
							className="text-gray-700 font-medium mb-2"
						>
							No. Pesanan
						</label>
						<input
							type="text"
							id="orderNumber"
							name="orderNumber"
							placeholder="Enter order number"
							value={state.orderNumber || ''}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>

					{/* Category */}
					<div className="flex flex-col">
						<label
							htmlFor="category"
							className="text-gray-700 font-medium mb-2"
						>
							Kategori
						</label>
						<select
							id="category"
							name="category"
							value={state.category}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						>
							<option value="">Select category</option>
							{categories.map((category) => (
								<option key={category} value={category}>
									{category}
								</option>
							))}
						</select>
					</div>

					{/* Order Name */}
					<div className="flex flex-col md:col-span-2">
						<label className="text-gray-700 font-medium mb-2">
							Nama Pesanan
						</label>
						<div className="flex flex-wrap gap-3">
							{orderNameOptions.map((name) => (
								<label
									key={name}
									className={`flex items-center gap-2 py-2 px-4 rounded-xl cursor-pointer ${
										state.orderName.includes(name)
											? 'bg-blue-500 text-white'
											: 'bg-gray-200 text-gray-800'
									}`}
								>
									<input
										type="checkbox"
										name="orderName"
										value={name}
										checked={state.orderName.includes(name)}
										onChange={() => handleOrderNameChange(name)}
										className="hidden"
									/>
									{name}
								</label>
							))}
							<input
								type="text"
								placeholder="Add custom name"
								value={manualOrderName}
								onChange={(e) => setManualOrderName(e.target.value)}
								onBlur={() => {
									if (manualOrderName) {
										handleOrderNameChange(manualOrderName)
										setManualOrderName('')
									}
								}}
								className="w-full px-4 py-2 border rounded-lg"
							/>
						</div>
					</div>

					{/* Quantity */}
					<div className="flex flex-col">
						<label
							htmlFor="quantity"
							className="text-gray-700 font-medium mb-2"
						>
							Jumlah
						</label>
						<input
							type="number"
							id="quantity"
							name="quantity"
							placeholder="Enter quantity"
							value={state.quantity || ''}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>

					{/* Email */}
					<div className="flex flex-col">
						<label htmlFor="email" className="text-gray-700 font-medium mb-2">
							Email (Optional)
						</label>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="Enter email"
							value={state.email || ''}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>

					{/* Date */}
					<div className="flex flex-col">
						<label htmlFor="date" className="text-gray-700 font-medium mb-2">
							Tanggal
						</label>
						<input
							type="datetime-local"
							id="date"
							name="date"
							value={displayDate}
							onChange={(e) => {
								const newDate = e.target.value
								setDisplayDate(newDate)
								setState((prev) => ({
									...prev,
									date: new Date(newDate).toISOString(),
								}))
							}}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
						<span className="text-gray-500 mt-2">
							Waktu dalam WIB: {formatToWIB(state.date)}
						</span>
					</div>

					{/* Status */}
					<div className="flex flex-col">
						<label
							htmlFor="isRedeemed"
							className="text-gray-700 font-medium mb-2"
						>
							Status
						</label>
						<select
							id="isRedeemed"
							name="isRedeemed"
							value={state.isRedeemed}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						>
							<option value="false">Belum Diklaim</option>
							<option value="true">Sudah Diklaim</option>
						</select>
					</div>

					{/* Submit Button */}
					<div className="md:col-span-2 flex justify-center">
						<button
							type="submit"
							className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white font-semibold py-2 rounded-xl"
						>
							{id ? 'Update' : 'Save'}
						</button>
					</div>
				</form>

				<Helmet>
					<title>{id ? 'Edit Shopee Order' : 'Add Shopee Order'} | Admin</title>
				</Helmet>
			</div>
		</div>
	)
}

export default AddEdit_ShopeeOrders
