import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Menu, MenuItem, Avatar, IconButton, Typography } from '@mui/material'
import { MdSpaceDashboard } from 'react-icons/md'
import { FaFolderOpen } from 'react-icons/fa'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import fireDb, { auth } from '../firebase'
import { signOut } from 'firebase/auth'

const Breadcrumbs = () => {
	const location = useLocation()
	const pathnames = location.pathname.split('/').filter((x) => x)

	const getBreadcrumbName = (path) => {
		const words = path.split('-')
		const capitalizedWords = words.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
		)
		return capitalizedWords.join(' ')
	}

	const translateBreadcrumb = (breadcrumb) => {
		const translations = {
			'List Shopee Orders': 'Daftar Pesanan Shopee',
			'List Vr Games': 'Daftar Game VR',
			'List Products': 'Daftar Produk',
			'List Users': 'Daftar Pengguna',
			'List Requests': 'Daftar Permintaan',
			'List Orders': 'Daftar Pesanan',
			'Upload Cover': 'Unggah Sampul',
			'Download Games Cover': 'Unduh Sampul Game',
			'Download Products Cover': 'Unduh Sampul Produk',
			'Upload To Drive': 'Unggah ke Google Drive',
			'Firmware Quest': 'Firmware Quest',
			Home: 'Beranda',
		}

		return translations[breadcrumb] || breadcrumb
	}

	const [user, setUser] = useState(null)
	const [profileData, setProfileData] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((currentUser) => {
			if (currentUser) {
				setUser(currentUser)
				const userRef = fireDb.ref(`users/${currentUser.uid}`)
				userRef.once('value', (snapshot) => {
					if (snapshot.exists()) {
						setProfileData(snapshot.val())
					} else {
						setProfileData(null)
					}
				})
			} else {
				setUser(null)
				setProfileData(null)
			}
		})
		return () => {
			unsubscribe()
		}
	}, [])

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleMenuClose = () => {
		setAnchorEl(null)
	}

	const handleSignOut = () => {
		signOut(auth)
			.then(() => {
				setUser(null)
				setProfileData(null)
			})
			.catch((error) => console.error('Error signing out:', error))
		handleMenuClose()
	}

	if (location.pathname === '/signin') {
		return null
	}

	return (
		<div className="px-4 py-2 bg-white flex justify-between items-center shadow-md rounded-b-2xl sticky top-0 h-[70px]">
			{/* Breadcrumbs Section */}
			<div className="flex items-center space-x-2">
				<Link
					to="/"
					className={`flex items-center ${window.location.pathname === '/' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-600 hover:bg-blue-600 hover:text-white'} rounded-full transition`}
				>
					<div className="px-4 py-2 flex items-center">
						<MdSpaceDashboard className="mr-2" />
						<Typography variant="body2">Beranda</Typography>
					</div>
				</Link>
				{pathnames.map((value, index) => {
					const to = `/${pathnames.slice(0, index + 1).join('/')}`
					const isLast = index === pathnames.length - 1
					const breadcrumbName = translateBreadcrumb(getBreadcrumbName(value))
					return (
						<div key={to} className="flex items-center space-x-2">
							<NavigateNextIcon fontSize="small" style={{ color: '#9e9e9e' }} />
							{isLast ? (
								<div className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full flex items-center">
									<FaFolderOpen className="mr-2" />
									<Typography variant="body2">{breadcrumbName}</Typography>
								</div>
							) : (
								<Link
									to={to}
									className="px-4 py-2 bg-gray-300 rounded-full flex items-center text-gray-600 hover:text-white hover:bg-blue-600 transition"
								>
									<FaFolderOpen className="mr-2" />
									<Typography variant="body2">{breadcrumbName}</Typography>
								</Link>
							)}
						</div>
					)
				})}
			</div>

			{/* User Profile Section */}
			<div className="flex items-center">
				{user ? (
					<>
						<IconButton onClick={handleMenuClick}>
							<Avatar
								alt={profileData?.name || user.displayName || 'User'}
								src={profileData?.photoURL || user.photoURL || ''}
								style={{ width: 40, height: 40 }}
							/>
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							<MenuItem disabled>
								<strong>
									{profileData?.name || user.displayName || 'User'}
								</strong>
							</MenuItem>
							<MenuItem disabled>{profileData?.email || user.email}</MenuItem>
							<MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
						</Menu>
					</>
				) : (
					<Link
						to="/signin"
						className="text-blue-600 font-medium hover:underline"
					>
						Sign In
					</Link>
				)}
			</div>
		</div>
	)
}

export default Breadcrumbs
