import React, { useState, useEffect } from 'react'
import fireDb from '../firebase'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'
import './Home.css'
import { FaPlus } from 'react-icons/fa'

const formattedDate = (timestamp) => {
	const date = new Date(timestamp)
	return date.toLocaleString('id-ID', {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		timeZoneName: 'short',
	})
}

const ListShopeeOrders_Admin = () => {
	const [dataOrders, setDataOrders] = useState({})

	useEffect(() => {
		const fetchData = () => {
			fireDb.ref('shopeeOrders').on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataOrders(snapshot.val())
				} else {
					setDataOrders({})
				}
			})
		}

		fetchData()

		return () => {
			fireDb.ref('shopeeOrders').off()
		}
	}, [])

	const updateOrderStatus = async (userId, orderId, newStatus) => {
		const order = dataOrders[userId]?.[orderId]

		if (order) {
			try {
				const verifiedPayment = ['Pesanan Dikirim', 'Pesanan Selesai'].includes(
					newStatus
				)

				await fireDb
					.ref(`orders/${userId}/${orderId}`)
					.update({ status: newStatus, verifiedPayment })

				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: `Order status updated to ${newStatus}`,
					confirmButtonColor: '#3085d6',
				})
			} catch (error) {
				console.error('Error updating order status:', error)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Failed to update order status. Please try again.',
				})
			}
		}
	}

	const editOrder = (orderId) => {
		window.location.href = `/list-shopee-orders/update/${orderId}`
	}

	const deleteOrder = async (orderId) => {
		Swal.fire({
			title: 'Hapus Pesanan?',
			text: 'Data pesanan ini akan dihapus secara permanen!',
			icon: 'info',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Ya, Hapus!',
			cancelButtonText: 'Batal',
		}).then((result) => {
			if (result.isConfirmed) {
				fireDb
					.ref(`shopeeOrders/${orderId}`)
					.remove()
					.then(() => {
						Swal.fire({
							icon: 'info',
							title: 'Berhasil!',
							text: 'Pesanan telah berhasil dihapus.',
							confirmButtonColor: '#3085d6',
						})
						// Refresh dataOrders after deletion
						setDataOrders((prevData) => {
							const updatedData = { ...prevData }
							delete updatedData[orderId]
							return updatedData
						})
					})
					.catch((error) => {
						console.error('Error deleting order:', error)
						Swal.fire({
							icon: 'error',
							title: 'Gagal!',
							text: 'Pesanan gagal dihapus. Silakan coba lagi.',
							confirmButtonColor: '#3085d6',
						})
					})
			}
		})
	}

	return (
		<div className={`pt-6 pb-10 px-6 w-full min-h-screen`}>
			<h1 className="text-3xl font-bold text-center">Daftar Pesanan Shopee</h1>
			<button
				className="bg-blue-500 text-white px-4 py-2.5 rounded-xl mt-4 w-full flex flex-row items-center justify-center gap-2"
				onClick={() => (window.location.href = '/list-shopee-orders/add')}
			>
				<p>Tambah Pesanan</p>
				<FaPlus />
			</button>
			<div className="flex flex-col mt-4">
				<div className="overflow-x-auto">
					<table className="min-w-full styled-table">
						<thead>
							<tr>
								<th>No</th>
								<th>No. Pesanan</th>
								<th>Kategori</th>
								<th>Nama Pesanan</th>
								<th>Jumlah</th>
								<th>Email</th>
								<th>Tanggal</th>
								<th>Status</th>
								<th>Aksi</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(dataOrders).map((orderId, index) => {
								const order = dataOrders[orderId]
								return (
									<tr key={orderId}>
										<td className="border border-gray-300 py-2 px-4">
											{index + 1}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{orderId}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{order?.category}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{order?.orderName?.join(', ') || '-'}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{order?.quantity}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{order?.email || '-'}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{formattedDate(order?.date)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											{order?.isRedeemed ? (
												<b className="text-[#0081FB]">Sudah Diklaim</b>
											) : (
												<b className="text-red-500">Belum Diklaim</b>
											)}
										</td>
										<td className="border border-gray-300 py-2 px-4">
											<button
												onClick={() => editOrder(orderId)}
												className="bg-blue-500 text-white px-4 py-1 rounded mr-2"
											>
												Edit
											</button>
											<button
												onClick={() => deleteOrder(orderId)}
												className="bg-red-500 text-white px-4 py-1 rounded"
											>
												Delete
											</button>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
			<Helmet>
				<title>Daftar Pesanan Shopee | HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default ListShopeeOrders_Admin
