import React, { useState, useEffect } from 'react'
import fireDb from '../firebase'
import { Link } from 'react-router-dom'
import './Home.css'

import { Helmet } from 'react-helmet'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import toast, { Toaster } from 'react-hot-toast'

const ListProducts_Admin = () => {
	const [data, setData] = useState({})

	useEffect(() => {
		fireDb.ref(`products`).on('value', (snapshot) => {
			if (snapshot.val() !== null) {
				setData({ ...snapshot.val() })
			} else {
				setData({})
			}
		})

		return () => {
			setData({})
		}
	}, [])

	const onDelete = (productTitle) => {
		if (window.confirm('Are you sure that you want to delete this game?')) {
			fireDb.ref(`products/${productTitle}`).remove((err) => {
				if (err) {
					toast.error(err)
				} else {
					toast.success('Game Deleted Successfully!')
				}
			})
		}
	}

	const limitStringLength = (str, maxLength) => {
		if (str && str.length > maxLength) {
			return str.substring(0, maxLength) + '...'
		}
		return str
	}

	const handleAddProductButton = () => {
		localStorage.setItem('dataTypeAddEdit', 'product')
		window.location.href = '/add'
	}

	const handleUpdateProductButton = (productTitle) => {
		localStorage.setItem('dataTypeAddEdit', 'product')
		window.location.href = `/update/${productTitle}`
	}

	return (
		<div className="pt-5 mx-5 pb-5">
			<Toaster />
			<button
				onClick={handleAddProductButton}
				className="text-center w-full py-2 mb-5 rounded-lg text-white font-semibold bg-[#0081FB] hover:bg-[#4083c2] transition-all duration-300"
			>
				<FaPlus className="inline-block mr-2" />
				<span>Add Products</span>
			</button>
			<div className="flex flex-col mt-4">
				<div className="overflow-x-auto">
					<table className="styled-table min-w-full rounded-2xl">
						<thead>
							<tr>
								<th>No</th>
								<th>Title</th>
								<th>Description (EN)</th>
								<th>Description (ID)</th>
								<th>Price</th>
								<th>Stock</th>
								<th>Time Added</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(data).map((productTitle, index) => (
								<tr key={productTitle}>
									<td className="border border-gray-300 py-2 px-4">
										{index + 1}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{data[productTitle].productName}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{limitStringLength(
											data[productTitle].productDescription_EN,
											20
										)}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{limitStringLength(
											data[productTitle].productDescription_ID,
											20
										)}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{data[productTitle].productPrice}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{data[productTitle].productStock}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										{data[productTitle].timeAdded}
									</td>
									<td className="border border-gray-300 py-2 px-4">
										<button
											className="btn btn-edit p-2"
											onClick={() => handleUpdateProductButton(productTitle)}
										>
											<FaPencil />
										</button>
										<button
											className="btn btn-delete p-2"
											onClick={() => onDelete(productTitle)}
										>
											<FaTrash />
										</button>
										{/* Add link to view if needed */}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<Helmet>
					<title>Daftar Produk - Admin | HyperTopia</title>
				</Helmet>
			</div>
		</div>
	)
}

export default ListProducts_Admin
