import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'
import { Helmet } from 'react-helmet'
import { FaArrowLeft } from 'react-icons/fa'
import toast, { Toaster } from 'react-hot-toast'

const initialState = {
	email: '',
	timeAdded: new Date().toISOString(),
}

const categories = ['standalone', 'pcvr', 'qgo']

const AddEdit_EligibleUsers = () => {
	const [state, setState] = useState(initialState)
	const [selectedCategories, setSelectedCategories] = useState([]) // Array for multiple categories
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		if (id) {
			// Fetch data from the first matching category
			Promise.all(
				categories.map((category) =>
					fireDb.ref(`eligibleUsers/${category}/${id}`).once('value')
				)
			).then((snapshots) => {
				snapshots.forEach((snapshot, index) => {
					if (snapshot.exists()) {
						setState(snapshot.val())
						setSelectedCategories((prev) => [...prev, categories[index]])
					}
				})
			})
		} else {
			setState({ ...initialState, timeAdded: new Date().toISOString() })
			setSelectedCategories([])
		}
	}, [id])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setState({ ...state, [name]: value })
	}

	const handleCategoryChange = (category) => {
		setSelectedCategories(
			(prev) =>
				prev.includes(category)
					? prev.filter((c) => c !== category) // Deselect category
					: [...prev, category] // Select category
		)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!state.email) {
			toast.error('Please provide a value in each input field!')
			return
		}
		if (selectedCategories.length === 0) {
			toast.error('Please select at least one category!')
			return
		}

		selectedCategories.forEach((category) => {
			const userData = {
				email: state.email,
				timeAdded: new Date().toISOString(),
			}

			fireDb
				.ref(
					`eligibleUsers/${category}/${state.email
						.split('@')[0]
						.replace(/\./g, '')}`
				)
				.set(userData, (err) => {
					if (err) {
						toast.error(err.message || 'Error occurred while saving user.')
					} else {
						toast.success(`User added to ${category} category successfully!`)
					}
				})
		})

		setTimeout(() => navigate('/list-users', { replace: true }), 500)
	}

	return (
		<div className={`pt-5 mx-5 pb-5 w-full `}>
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className="py-4 px-6 m-auto w-full flex flex-col gap-6 rounded-2xl bg-white shadow-lg"
					onSubmit={handleSubmit}
				>
					<Link
						to="/list-users"
						className="flex items-center justify-center py-2 mb-4 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
					>
						<FaArrowLeft className="mr-2" />
						Back to List Users
					</Link>

					<h2 className="text-xl font-semibold text-center mb-4">
						{id ? 'Edit Eligible User' : 'Add Eligible User'}
					</h2>

					<hr className="border-[1px] border-gray-300 rounded" />

					{/* Checkbox Categories */}
					<div className="flex flex-col items-start">
						<label className="text-gray-700 font-medium mb-2">
							Select Categories
						</label>
						<div className="flex flex-wrap gap-3">
							{categories.map((category) => (
								<label
									key={category}
									className={`flex items-center gap-2 py-2 px-4 rounded-xl cursor-pointer ${
										selectedCategories.includes(category)
											? 'bg-blue-500 text-white'
											: 'bg-gray-200 text-gray-800'
									}`}
								>
									<input
										type="checkbox"
										name="categories"
										value={category}
										checked={selectedCategories.includes(category)}
										onChange={() => handleCategoryChange(category)}
										className="hidden"
									/>
									{category.toUpperCase()}
								</label>
							))}
						</div>
					</div>

					{/* Email Input */}
					<div className="flex flex-col">
						<label htmlFor="email" className="text-gray-700 font-medium mb-2">
							Email
						</label>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="Enter email"
							value={state.email || ''}
							onChange={handleInputChange}
							className="w-full px-4 py-2 border rounded-lg shadow-inner focus:outline-none focus:ring-2 focus:ring-blue-500"
						/>
					</div>

					{/* Submit Button */}
					<button
						type="submit"
						className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white font-semibold py-2 rounded-xl"
					>
						{id ? 'Update' : 'Save'}
					</button>
				</form>

				<Helmet>
					<title>
						{id ? 'Edit Eligible User' : 'Add Eligible User'} | Admin
					</title>
				</Helmet>
			</div>
		</div>
	)
}

export default AddEdit_EligibleUsers
