import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'

import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import { storage } from '../firebase'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { FaArrowLeft, FaTrash } from 'react-icons/fa'
import Compressor from 'compressorjs'
import { FaPlus } from 'react-icons/fa6'
import toast, { Toaster } from 'react-hot-toast'

const initialState_EligibleUsers = {
	email: '',
	timeAdded: new Date().toISOString(),
}

const AddEdit_PCVR_EligibleUsers = () => {
	const [userType, setUserType] = useState('standalone') // Default to 'standalone'

	const [state_EligibleUsers, setState_EligibleUsers] = useState(
		initialState_EligibleUsers
	)

	const { isDarkMode } = useDarkModeContext()
	const navigate = useNavigate()
	const { id } = useParams()

	const language = localStorage.getItem('language') || 'id'

	const [dataType, setDataType] = useState(
		localStorage.getItem('dataTypeAddEdit')
	) // Default to 'vrGames'

	const [dataAddUserType, setDataAddUserType] = useState(
		localStorage.getItem('dataAddUserType')
	) // Default to 'standalone'

	const [gameImageUrl, setGameImageUrl] = useState(null)

	useEffect(() => {
		if (id) {
			fireDb.ref(`eligibleUsers/pcvr/${id}`).on('value', (snapshot) => {
				const existingData = snapshot.val()
				if (existingData) {
					setState_EligibleUsers({
						email: existingData.email || '',
					})
				} else {
					setState_EligibleUsers(initialState_EligibleUsers)
				}
			})
		} else {
			setState_EligibleUsers({
				...initialState_EligibleUsers,
				timeAdded: new Date().toISOString(),
			})
		}

		return () => {
			fireDb.ref(`eligibleUsers/pcvr/${id}`).off('value')
		}
	}, [id, dataType])

	const handleInputChange_EligibleUsers = (e) => {
		const { name, value } = e.target
		setState_EligibleUsers({ ...state_EligibleUsers, [name]: value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!state_EligibleUsers.email) {
			toast.error('Please provide a value in each input field!')
		} else {
			const eligibleUserData = {
				email: state_EligibleUsers.email, // Gunakan bagian sebelum "@" sebagai email
				timeAdded: new Date().toISOString(),
			}

			if (!id) {
				fireDb
					.ref(`eligibleUsers/pcvr/${state_EligibleUsers.email.split('@')[0]}`)
					.set(eligibleUserData, (err) => {
						if (err) {
							toast.error(err)
						} else {
							toast.success('User Added Successfully!')
						}
					})
			} else {
				fireDb.ref(`eligibleUsers/pcvr/${id}`).set(eligibleUserData, (err) => {
					if (err) {
						toast.error(err)
					} else {
						toast.success('User Updated Successfully!')
					}
				})
			}
			setTimeout(() => navigate('/list-users', { replace: true }), 500)
		}
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	return (
		<div className="mx-5 pt-6 mb-10 w-full">
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'}`}
					onSubmit={handleSubmit}
				>
					<Link
						to="/list-users"
						className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
					>
						<FaArrowLeft className="mr-2" />
						Back to List Users
					</Link>

					<hr className="border-[1px] border-gray-300 rounded" />
					<div className="flex gap-3 items-center justify-between w-full">
						<label className="w-64" htmlFor="email">
							<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								Email
							</span>
						</label>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="Fill Here"
							value={state_EligibleUsers.email || ''}
							onChange={handleInputChange_EligibleUsers}
							className="rounded p-2 input-box w-full"
						/>
					</div>

					<input
						className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
						type="submit"
						value={id ? 'Update' : 'Save'}
					/>
				</form>

				<Helmet>
					<title>
						{id ? 'Edit Eligible User' : 'Add Eligible User'} | Admin
					</title>
				</Helmet>
			</div>

			<style>
				{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default AddEdit_PCVR_EligibleUsers
