import React, { useState, useEffect } from 'react'
import fireDb from '../firebase'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'
import './Home.css'

const ListOrders_Admin = () => {
	const [dataOrders, setDataOrders] = useState({})
	const [dataEligibleUsers, setDataEligibleUsers] = useState({})

	useEffect(() => {
		const fetchData = () => {
			fireDb.ref('orders').on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataOrders(snapshot.val())
				} else {
					setDataOrders({})
				}
			})
		}

		const fetchEligibleUsers = () => {
			fireDb.ref('eligibleUsers/standalone').on('value', (snapshot) => {
				if (snapshot.val() !== null) {
					setDataEligibleUsers(snapshot.val())
				} else {
					setDataEligibleUsers({})
				}
			})
		}

		fetchData()
		fetchEligibleUsers()

		return () => {
			fireDb.ref('orders').off()
			fireDb.ref('eligibleUsers/standalone').off()
		}
	}, [])

	const updateOrderStatus = async (userId, orderId, newStatus) => {
		const order = dataOrders[userId]?.[orderId]

		if (order) {
			try {
				// Set verifiedPayment: true for specific statuses
				const verifiedPayment = ['Pesanan Dikirim', 'Pesanan Selesai'].includes(
					newStatus
				)

				await fireDb
					.ref(`orders/${userId}/${orderId}`)
					.update({ status: newStatus, verifiedPayment })

				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: `Order status updated to ${newStatus}`,
					confirmButtonColor: '#3085d6',
				})
			} catch (error) {
				console.error('Error updating order status:', error)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Failed to update order status. Please try again.',
				})
			}
		}
	}

	const deleteOrder = async (userId, orderId) => {
		Swal.fire({
			title: 'Are you sure?',
			text: 'This action cannot be undone!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Yes, delete it!',
		}).then((result) => {
			if (result.isConfirmed) {
				fireDb.ref(`orders/${userId}/${orderId}`).remove()

				Swal.fire({
					icon: 'success',
					title: 'Deleted!',
					text: 'The order has been deleted.',
					confirmButtonColor: '#3085d6',
				})
			}
		})
	}

	return (
		<div className={`pt-6 pb-10 px-6 w-full  min-h-screen`}>
			<h1 className="text-3xl font-bold text-center">Orders</h1>
			<div className="flex flex-col mt-4">
				<div className="overflow-x-auto">
					<table className="min-w-full styled-table">
						<thead>
							<tr>
								<th>No</th>
								<th>Order ID</th>
								<th>User ID</th>
								<th>Product Name</th>
								<th>Quantity</th>
								<th>Emails</th>
								<th>Total Price</th>
								<th>Order Date</th>
								<th>Status</th>
								<th>Payment Proof</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(dataOrders).map((userId, index) =>
								Object.keys(dataOrders[userId]).map((orderId) => {
									const order = dataOrders[userId]?.[orderId]
									return (
										<tr key={orderId}>
											<td className="border border-gray-300 py-2 px-4">
												{index + 1}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{orderId}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{userId}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.items[0]?.productName}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.items[0]?.quantity}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.items[0]?.emails?.map((email, i) => (
													<div key={i}>{email}</div>
												)) || 'No emails'}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.totalPrice}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.timeCreated}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												<select
													value={order?.status || ''}
													onChange={(e) =>
														updateOrderStatus(userId, orderId, e.target.value)
													}
													className="border rounded px-2 py-1"
												>
													<option value="Menunggu Verifikasi">
														Menunggu Verifikasi
													</option>
													<option value="Pesanan Dikirim">
														Pesanan Dikirim
													</option>
													<option value="Pesanan Selesai">
														Pesanan Selesai
													</option>
												</select>
											</td>
											<td className="border border-gray-300 py-2 px-4">
												{order?.paymentProof ? (
													<a
														href={order.paymentProof}
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 underline"
													>
														View Proof
													</a>
												) : (
													'No proof'
												)}
											</td>
											<td className="border border-gray-300 py-2 px-4">
												<button
													onClick={() => deleteOrder(userId, orderId)}
													className="bg-red-500 text-white px-4 py-1 rounded"
												>
													Delete
												</button>
											</td>
										</tr>
									)
								})
							)}
						</tbody>
					</table>
				</div>
			</div>
			<Helmet>
				<title>Daftar Pesanan - HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default ListOrders_Admin
