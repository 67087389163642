import React, { useState, useEffect } from 'react'
import { auth, provider } from '../firebase'
import { useNavigate } from 'react-router-dom'
import logoHypertopia from '../assets/images/NewLogoOnly.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { signInWithPopup } from 'firebase/auth'
import { FcGoogle } from 'react-icons/fc'
import { Helmet } from 'react-helmet'
import toast, { Toaster } from 'react-hot-toast'

const AdminSignIn = () => {
	const [admin, setAdmin] = useState(null)
	const navigate = useNavigate()

	const handleGoogleSignIn = async () => {
		try {
			const result = await signInWithPopup(auth, provider)
			const user = result.user

			const response = await fetch(
				'https://api.hypertopia.shop/api/validate_uid',
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ email: user.email }),
				}
			)

			console.log('Response:', response)

			const data = await response.json()

			if (data.allowed) {
				setAdmin(user)
				navigate('/')
			} else {
				toast.error('Akses ditolak! Email Anda tidak diizinkan.')
			}
		} catch (error) {
			console.error('Login gagal:', error.message)
			toast.error('Login gagal! Silakan coba lagi.')
		}
	}

	if (admin) {
		return (
			<div className="flex items-center justify-center h-screen w-full bg-gradient-to-br from-blue-500 to-indigo-900">
				<Toaster position="top-right" reverseOrder={false} />{' '}
				{/* Tambahkan Toaster */}
				<div className="p-8 border-2 shadow-lg w-[375px] rounded-3xl flex flex-col items-center bg-white">
					<h3 className="text-center text-lg font-semibold text-gray-800">
						Selamat Datang, <br />
						<span className="font-bold">{admin.displayName}</span>
					</h3>
					<img
						src={admin.photoURL}
						alt="admin"
						referrerPolicy="no-referrer"
						className="rounded-full border-4 mt-4 shadow-md"
					/>
					<p className="text-gray-600 mt-4">{admin.email}</p>
					<p className="mt-4 text-sm text-gray-500">
						Anda akan diarahkan ke Dasbor Admin...
					</p>
				</div>
			</div>
		)
	}

	return (
		<div className="flex items-center justify-center h-screen w-full bg-gradient-to-br from-blue-500 to-indigo-900">
			<Toaster reverseOrder={false} /> {/* Tambahkan Toaster */}
			<div className="p-10 border-2 rounded-3xl w-[400px] flex flex-col items-center shadow-xl bg-white">
				<LazyLoadImage
					src={logoHypertopia}
					alt="hypertopia-logo"
					className="mb-5"
					width={80}
				/>
				<h1 className="mb-3 font-bold text-gray-800 text-xl">
					Masuk ke Dasbor Admin
				</h1>
				<p className="mb-6 text-sm text-center text-gray-600">
					Hanya admin yang memiliki akses ke halaman ini. Silahkan login dengan
					email Google yang telah disetujui.
				</p>
				<button
					className="hover:bg-gray-100 transition-all h-full duration-300 ease-in-out w-full bg-gray-200 border-2 py-3 rounded-xl flex flex-row gap-2 items-center justify-center shadow-md hover:shadow-lg"
					onClick={handleGoogleSignIn}
				>
					<FcGoogle className="w-6 h-6" />
					<p className="font-semibold text-gray-800">Masuk dengan Google</p>
				</button>
			</div>
			<Helmet>
				<title>Masuk Dasbor Admin | Hypertopia</title>
			</Helmet>
		</div>
	)
}

export default AdminSignIn
