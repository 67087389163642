import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import fireDb from '../firebase'

import { Helmet } from 'react-helmet'
import { useDarkModeContext } from '../DarkModeContext'
import { storage } from '../firebase'
import { getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import { FaArrowLeft, FaTrash } from 'react-icons/fa'
import Compressor from 'compressorjs'
import { FaPlus } from 'react-icons/fa6'
import toast, { Toaster } from 'react-hot-toast'

const initialState_VRGames = {
	newAdded: false,
	dataType: 'vrGames',
	gameTitle: '',
	linkMetaStore: '',
	linkDownload: '',
	gameVersion: 'v',
	downloadCount: 0,
	likedCount: 0,
	gameSize: '',
	releaseDate: new Date().toISOString().split('T')[0],
	gameStatus: 'new',
	mixedReality: 'no',
	supportMetaQuest3: true,
	supportMetaQuestPro: true,
	supportMetaQuest2: true,
	supportMetaQuest1: true,
	photoUrl: '',
	imageUrl: '', // Menyimpan URL gambar yang akan diunduh
	compressedImage: null, // Menyimpan gambar yang telah dikompresi
	videoIdYouTube: '',
	timeAdded: new Date().toISOString(),
}

const initialState_Product = {
	dataType: 'product',
	productName: '',
	productPhoto: null,
	productDescription_EN: '',
	productDescription_ID: '',
	productPrice: 0,
	productStock: 0,
	timeAdded: new Date().toISOString(),
	productLinkTokopedia: '',
	productLinkShopee: '',
}

const initialState_EligibleUsers = {
	email: '',
	timeAdded: new Date().toISOString(),
}

const AddEdit = () => {
	const [userTypeAndGames, setUserTypeAndGames] = useState('standalone') // Default to 'standalone'

	const [state_VRGames, setState_VRGames] = useState(initialState_VRGames)
	const [state_Product, setState_Product] = useState(initialState_Product)
	const [state_EligibleUsers, setState_EligibleUsers] = useState(
		initialState_EligibleUsers
	)
	const [imageUpload_VRGames, setImageUpload_VRGames] = useState(null)
	const [imageUpload_Product, setImageUpload_Product] = useState(null)

	const [isImageUploaded_VRGames, setIsImageUploaded_VRGames] = useState(false)
	const [isImageUploaded_Product, setIsImageUploaded_Product] = useState(false)

	const [imageLink, setImageLink] = useState('') // State untuk menyimpan URL gambar dari input

	const { isDarkMode } = useDarkModeContext()
	const [data, setData] = useState({})
	const navigate = useNavigate()
	const { id } = useParams()

	const imageListRef = ref(storage, 'images/')

	const language = localStorage.getItem('language') || 'id'

	const [dataType, setDataType] = useState(
		localStorage.getItem('dataTypeAddEdit')
	) // Default to 'vrGames'

	const [dataAddUserType, setDataAddUserType] = useState(
		localStorage.getItem('dataAddUserType')
	) // Default to 'standalone'
	const [dataAddGameType, setDataAddGameType] = useState(
		localStorage.getItem('dataAddGameType')
	) // Default to 'standalone'

	const [gameImageUrl, setGameImageUrl] = useState(null)

	useEffect(() => {
		if (id) {
			if (dataType === 'vrGames') {
				fireDb
					.ref(`vrGames/${userTypeAndGames}/${id}`)
					.on('value', (snapshot) => {
						const existingData = snapshot.val()
						if (existingData) {
							setState_VRGames({
								newAdded: existingData.newAdded || false,
								gameTitle: existingData.gameTitle || '',
								linkMetaStore: existingData.linkMetaStore || '',
								linkDownload: existingData.linkDownload || '',
								downloadLinks: existingData.linkDownload
									? existingData.linkDownload.split('\n')
									: [''],
								gameVersion: existingData.gameVersion || '',
								downloadCount: existingData.downloadCount || 0,
								likedCount: existingData.likedCount || 0,
								gameSize: existingData.gameSize || '',
								releaseDate:
									existingData.releaseDate ||
									new Date().toISOString().split('T')[0],
								gameStatus: existingData.gameStatus || 'new',
								mixedReality: existingData.mixedReality || 'no',
								supportMetaQuest3: existingData.supportMetaQuest3 || false,
								supportMetaQuestPro: existingData.supportMetaQuestPro || false,
								supportMetaQuest2: existingData.supportMetaQuest2 || false,
								supportMetaQuest1: existingData.supportMetaQuest1 || false,
								photoUrl: existingData.photoUrl || '',
								videoIdYouTube: existingData.videoIdYouTube || '',
								timeAdded:
									existingData.gameStatus === 'nothing'
										? existingData.timeAdded
										: new Date().toISOString(),
							})
						} else {
							setState_VRGames(initialState_VRGames)
						}
					})
			} else if (dataType === 'product') {
				fireDb.ref(`products/${id}`).on('value', (snapshot) => {
					const existingData = snapshot.val()
					if (existingData) {
						setState_Product({
							productName: existingData.productName || '',
							productPhoto: existingData.productPhoto || null,
							productDescription_EN: existingData.productDescription_EN || '',
							productDescription_ID: existingData.productDescription_ID || '',
							productPrice: existingData.productPrice || 0,
							productStock: existingData.productStock || 0,
							timeAdded: new Date().toISOString(),
							productLinkTokopedia: existingData.productLinkTokopedia || '',
							productLinkShopee: existingData.productLinkShopee || '',
						})
					} else {
						setState_Product(initialState_Product)
					}
				})
			} else if (dataType === 'eligibleUser') {
				fireDb
					.ref(`eligibleUsers/${userTypeAndGames}/${id}`)
					.on('value', (snapshot) => {
						const existingData = snapshot.val()
						if (existingData) {
							setState_EligibleUsers({
								email: existingData.email || '',
							})
						} else {
							setState_EligibleUsers(initialState_EligibleUsers)
						}
					})
			}
		} else {
			if (dataType === 'vrGames') {
				setState_VRGames({
					...initialState_VRGames,
					downloadLinks: [''],
					timeAdded: new Date().toISOString(),
				})
			} else if (dataType === 'product') {
				setState_Product({
					...initialState_Product,
					timeAdded: new Date().toISOString(),
				})
			} else if (dataType === 'eligibleUser') {
				setState_EligibleUsers({
					...initialState_EligibleUsers,
					timeAdded: new Date().toISOString(),
				})
			}
		}

		return () => {
			if (dataType === 'vrGames') {
				fireDb.ref(`vrGames/${userTypeAndGames}/${id}`).off('value')
			} else if (dataType === 'product') {
				fireDb.ref(`products/${id}`).off('value')
			} else if (dataType === 'eligibleUser') {
				fireDb.ref(`eligibleUsers/${userTypeAndGames}/${id}`).off('value')
			}
		}
	}, [id, dataType])

	useEffect(() => {
		setState_VRGames((prevState) => ({
			...prevState,
			supportMetaQuest3: prevState.supportMetaQuest3 || false,
			supportMetaQuestPro: prevState.supportMetaQuestPro || false,
			supportMetaQuest2: prevState.supportMetaQuest2 || false,
			supportMetaQuest1: prevState.supportMetaQuest1 || false,
		}))
	}, [
		state_VRGames.supportMetaQuest3,
		state_VRGames.supportMetaQuestPro,
		state_VRGames.supportMetaQuest2,
		state_VRGames.supportMetaQuest1,
	])

	const handleInputChange_NewAdded = (e) => {
		const { name, value, type, checked } = e.target
		const inputValue = type === 'checkbox' ? checked : value
		setState_VRGames({ ...state_VRGames, [name]: inputValue })
	}

	const handleInputChange_VRGames = (e) => {
		const { name, value, type, checked } = e.target
		const inputValue = type === 'checkbox' ? checked : value

		if (name === 'linkDownload') {
			// Jika name adalah linkDownload, perbarui state downloadLinks
			setDownloadLinks((prevLinks) => [...prevLinks, inputValue])
		} else {
			// Jika bukan linkDownload, perbarui state state_VRGames seperti sebelumnya
			setState_VRGames({ ...state_VRGames, [name]: inputValue })
		}
	}

	const handleImageLinkChange = (e) => {
		// Mendapatkan nilai input dan membersihkannya dari "url(" dan ")"
		const cleanedUrl = cleanImageUrl(e.target.value)
		setImageLink(cleanedUrl)
	}

	const handleInputChange_Product = (e) => {
		const { name, value, type, files } = e.target
		const inputValue = type === 'file' ? files[0] : value
		setState_Product({ ...state_Product, [name]: inputValue })
	}

	const handleInputChange_EligibleUsers = (e) => {
		const { name, value } = e.target
		setState_EligibleUsers({ ...state_EligibleUsers, [name]: value })
	}

	const handleDataTypeChange = (e) => {
		setDataType(e.target.value)
	}

	const handleDataAddTypeUser = (e) => {
		setDataAddUserType(e.target.value)
		localStorage.setItem('dataAddUserType', e.target.value)
		setUserTypeAndGames(localStorage.getItem('dataAddUserType'))
	}

	const handleDataAddTypeGame = (e) => {
		setDataAddGameType(e.target.value)
		localStorage.setItem('dataAddGameType', e.target.value)
		setUserTypeAndGames(localStorage.getItem('dataAddGameType'))
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (dataType === 'vrGames') {
			if (!state_VRGames.gameTitle) {
				toast.error('Please provide a value in each input field!')
			} else {
				const timeAddedValue =
					state_VRGames.gameStatus !== 'nothing'
						? new Date().toISOString()
						: state_VRGames.timeAdded
				const gameData = {
					newAdded: state_VRGames.newAdded,
					dataType: 'vrGames',
					gameTitle: state_VRGames.gameTitle,
					linkMetaStore: state_VRGames.linkMetaStore,
					linkDownload: downloadLinks.join('\n'),
					gameVersion: state_VRGames.gameVersion,
					downloadCount: parseInt(state_VRGames.downloadCount),
					likedCount: parseInt(state_VRGames.likedCount),
					gameSize: state_VRGames.gameSize,
					releaseDate: state_VRGames.releaseDate,
					supportMetaQuest3: state_VRGames.supportMetaQuest3,
					supportMetaQuestPro: state_VRGames.supportMetaQuestPro,
					supportMetaQuest2: state_VRGames.supportMetaQuest2,
					supportMetaQuest1: state_VRGames.supportMetaQuest1,
					gameStatus: state_VRGames.gameStatus,
					mixedReality: state_VRGames.mixedReality,
					photoUrl: state_VRGames.photoUrl,
					videoIdYouTube: state_VRGames.videoIdYouTube,
					timeAdded: timeAddedValue,
				}

				if (!id) {
					fireDb
						.ref(`vrGames/${userTypeAndGames}/${state_VRGames.gameTitle}`)
						.set(gameData, (err) => {
							if (err) {
								toast.error(err)
							} else {
								toast.success('Game Added Successfully!')
							}
						})
				} else {
					fireDb
						.ref(`vrGames/${userTypeAndGames}/${id}`)
						.set(gameData, (err) => {
							if (err) {
								toast.error(err)
							} else {
								toast.success('Game Updated Successfully!')
							}
						})
				}

				setTimeout(() => navigate('/list-vr-games', { replace: true }), 500)
			}
		} else if (dataType === 'product') {
			if (
				!state_Product.productName ||
				!state_Product.productDescription_EN ||
				!state_Product.productDescription_ID ||
				!state_Product.productPrice ||
				!state_Product.productStock
			) {
				toast.error('Please provide a value in each input field!')
			} else {
				const productData = {
					dataType: 'product',
					productName: state_Product.productName,
					productDescription_EN: state_Product.productDescription_EN,
					productDescription_ID: state_Product.productDescription_ID,
					productPrice: parseInt(state_Product.productPrice),
					productStock: parseInt(state_Product.productStock),
					timeAdded: isImageUploaded_VRGames
						? state_Product.timeAdded
						: new Date().toISOString(),
					productLinkTokopedia: state_Product.productLinkTokopedia,
					productLinkShopee: state_Product.productLinkShopee,
				}

				if (!id) {
					fireDb
						.ref(`products/${state_Product.productName}`)
						.set(productData, (err) => {
							if (err) {
								toast.error(err)
							} else {
								toast.success('Product Added Successfully!')
							}
						})
				} else {
					fireDb.ref(`products/${id}`).set(productData, (err) => {
						if (err) {
							toast.error(err)
						} else {
							toast.success('Product Updated Successfully!')
						}
					})
				}
				setTimeout(() => navigate('/list-products', { replace: true }), 500)
			}
		} else if (dataType === 'eligibleUser') {
			if (!state_EligibleUsers.email) {
				toast.error('Please provide a value in each input field!')
			} else {
				const eligibleUserData = {
					email: state_EligibleUsers.email, // Gunakan bagian sebelum "@" sebagai email
					timeAdded: new Date().toISOString(),
				}

				if (!id) {
					fireDb
						.ref(
							`eligibleUsers/${userTypeAndGames}/${state_EligibleUsers.email.split('@')[0]}`
						)
						.set(eligibleUserData, (err) => {
							if (err) {
								toast.error(err)
							} else {
								toast.success('User Added Successfully!')
							}
						})
				} else {
					fireDb
						.ref(`eligibleUsers/${userTypeAndGames}/${id}`)
						.set(eligibleUserData, (err) => {
							if (err) {
								toast.error(err)
							} else {
								toast.success('User Updated Successfully!')
							}
						})
				}
				setTimeout(() => navigate('/list-users', { replace: true }), 500)
			}
		}
	}

	useEffect(() => {
		listAll(imageListRef).then((response) => {
			const gameImageName = `${state_VRGames.gameTitle}`
			const userImage = response.items.find(
				(item) => item.name === gameImageName
			)
			if (userImage) {
				getDownloadURL(userImage).then((url) => {
					setGameImageUrl(url)
					setImageLink(url)
				})
			}
		})
	}, [state_VRGames.gameTitle, imageListRef])

	const uploadImage_VRGames = () => {
		if (imageLink) {
			const xhr = new XMLHttpRequest()
			xhr.open('GET', imageLink)
			xhr.responseType = 'blob'
			xhr.onload = () => {
				const blob = xhr.response
				const fileName = state_VRGames.gameTitle // Gunakan gameTitle tanpa mengubah apapun
				new Compressor(blob, {
					quality: 0.7,
					maxWidth: 650,
					success(compressedBlob) {
						const imageRef_VRGames = ref(
							storage,
							`coverGamesImages/${fileName}`
						)
						uploadBytes(imageRef_VRGames, compressedBlob).then(() => {
							setIsImageUploaded_VRGames(true)
						})
						getDownloadURL(imageRef_VRGames).then((url) => {
							setState_VRGames({ ...state_VRGames, photoUrl: url })
						})
					},
					error(error) {
						console.error('Error compressing image:', error)
						toast.error('Failed to compress image.')
					},
				})
			}
			xhr.send()
		}
	}

	const uploadImage_Product = () => {
		if (imageUpload_Product == null) return
		const imageName_Product = `${state_Product.productName}`
		const imageRef_Product = ref(storage, `productPhotos/${imageName_Product}`)
		uploadBytes(imageRef_Product, imageUpload_Product).then(() => {
			setIsImageUploaded_Product(true)
		})
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	const [downloadLinks, setDownloadLinks] = useState(
		state_VRGames.linkDownload ? state_VRGames.linkDownload.split('\n') : ['']
	)

	const handleAddDownloadLink = () => {
		setDownloadLinks([...downloadLinks, ''])
	}

	const handleRemoveDownloadLink = (index) => {
		const updatedLinks = [...downloadLinks]
		updatedLinks.splice(index, 1)
		setDownloadLinks(updatedLinks)
	}

	const handleDownloadLinkChange = (index, e) => {
		const updatedLinks = [...downloadLinks]
		updatedLinks[index] = e.target.value
		setDownloadLinks(updatedLinks)
	}

	const cleanImageUrl = (imageUrl) => {
		// Hapus "url(" dari awal string dan ")" dari akhir string
		return imageUrl.replace(/^url\("(.*)"\)$/, '$1')
	}

	// Set download links from database to state
	useEffect(() => {
		if (state_VRGames.linkDownload) {
			const linksArray = state_VRGames.linkDownload.split('\n')
			setDownloadLinks(linksArray)
		}
	}, [state_VRGames.linkDownload])

	return (
		<div className="mx-5 pt-6 mb-10 w-full">
			<Toaster />
			<div className="flex flex-col items-center justify-center">
				<form
					className={`py-4 px-6 m-auto w-full content-center flex flex-col gap-6 rounded-2xl ${isDarkMode ? 'bg-zinc-800' : 'bg-white'}`}
					onSubmit={handleSubmit}
				>
					{dataType === 'vrGames' && (
						<Link
							to="/list-vr-games"
							className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
						>
							<FaArrowLeft className="mr-2" />
							Back to List VR Games
						</Link>
					)}
					{dataType === 'product' && (
						<Link
							to="/list-products"
							className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
						>
							<FaArrowLeft className="mr-2" />
							Back to List Products
						</Link>
					)}
					{dataType === 'eligibleUser' && (
						<Link
							to="/list-users"
							className="flex items-center w-full justify-center py-2 hover:bg-red-600 transition-all duration-300 text-white font-semibold rounded-xl bg-red-500"
						>
							<FaArrowLeft className="mr-2" />
							Back to List Users
						</Link>
					)}
					<div className="flex gap-3 items-center justify-between w-full">
						<label className="w-64" htmlFor="dataType">
							<span className={`${isDarkMode ? 'text-white' : 'text-black'}`}>
								Data Type:
							</span>
						</label>
						<div className="flex flex-row gap-2 justify-start w-full">
							<select
								id="dataType"
								name="dataType"
								value={localStorage.getItem('dataTypeAddEdit') || dataType}
								onChange={handleDataTypeChange}
								className={`text-black outline-none rounded-xl px-4 py-3 input-box w-full`}
								disabled
							>
								<option value="vrGames">VR Games</option>
								<option value="product">Product</option>
								<option value="eligibleUser">Eligible User</option>
							</select>
						</div>
					</div>

					<hr className="border-[1px] border-gray-300 rounded" />

					{/* UNTUK TIPE VR GAMES */}
					{dataType === 'vrGames' && (
						<>
							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="dataType">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Game Type:
									</span>
								</label>
								<div className="flex flex-row gap-2 justify-start w-full">
									<select
										id="dataType"
										name="dataType"
										value={
											localStorage.getItem('dataAddGameType') || dataAddGameType
										}
										onChange={handleDataAddTypeGame}
										className={`text-black outline-none rounded-xl px-4 py-3 input-box w-full`}
									>
										<option value="standalone">Standalone</option>
										<option value="pcvr">PCVR</option>
									</select>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="gameTitle">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Game Title
									</span>
								</label>
								<input
									type="text"
									id="gameTitle"
									name="gameTitle"
									placeholder="Fill Here"
									value={state_VRGames.gameTitle || ''}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="linkMetaStore">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Link Meta Store
									</span>
								</label>
								<input
									type="url"
									id="linkMetaStore"
									name="linkMetaStore"
									placeholder="Fill Here"
									value={state_VRGames.linkMetaStore || ''}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="linkDownload">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Link Download
									</span>
								</label>
								<div className="flex flex-col gap-2 w-full">
									{downloadLinks.map((link, index) => (
										<div key={index} className="flex gap-2 items-center">
											<input
												type="url"
												id={`linkDownload_${index}`}
												name={`linkDownload_${index}`}
												placeholder="Fill Here"
												value={link || ''} // Set value to the corresponding link in downloadLinks array
												onChange={(e) => handleDownloadLinkChange(index, e)}
												className="rounded p-2 input-box w-full"
											/>
											<button
												type="button"
												onClick={() => handleRemoveDownloadLink(index)}
												className="bg-red-500 text-white p-4 rounded-lg hover:bg-red-700 transition-all duration-300"
											>
												<FaTrash />
											</button>
										</div>
									))}
									<button
										type="button"
										onClick={handleAddDownloadLink}
										className="bg-green-500 text-white px-3 py-2 rounded-lg hover:bg-green-700 transition-all duration-300 w-full flex flex-row gap-2 items-center justify-center"
									>
										Add Download Link
										<FaPlus />
									</button>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="gameVersion">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Version
									</span>
								</label>
								<input
									type="text"
									id="gameVersion"
									name="gameVersion"
									placeholder="Fill Here"
									value={state_VRGames.gameVersion || ''}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="downloadCount">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Download Count
									</span>
								</label>
								<input
									type="number"
									id="downloadCount"
									name="downloadCount"
									value={state_VRGames.downloadCount}
									onChange={handleInputChange_VRGames}
									className={`rounded p-2 input-box w-full`}
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="likedCount">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Liked Count
									</span>
								</label>
								<input
									type="number"
									id="likedCount"
									name="likedCount"
									value={state_VRGames.likedCount}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="gameSize">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Game Size
									</span>
								</label>
								<input
									type="text"
									id="gameSize"
									name="gameSize"
									placeholder="Fill Here"
									value={state_VRGames.gameSize || ''}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64">Status:</label>
								<div className="flex gap-2 items-center w-full">
									<div
										className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'new' ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'text-black hover:bg-gray-200'}`}
									>
										<input
											type="radio"
											name="gameStatus"
											value="new"
											checked={state_VRGames.gameStatus === 'new'}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="newStatusRadio"
										/>
										<label htmlFor="newStatusRadio" className="cursor-pointer">
											New
										</label>
									</div>
									<div
										className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'update' ? 'bg-gradient-to-br from-yellow-300 to-yellow-500 text-white' : 'text-black hover:bg-gray-200'}`}
									>
										<input
											type="radio"
											name="gameStatus"
											value="update"
											checked={state_VRGames.gameStatus === 'update'}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="updateStatusRadio"
										/>
										<label
											htmlFor="updateStatusRadio"
											className="cursor-pointer"
										>
											Update
										</label>
									</div>
									<div
										className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'soon' ? 'bg-gradient-to-br from-green-300 to-green-500 text-white' : 'text-black hover:bg-gray-200'}`}
									>
										<input
											type="radio"
											name="gameStatus"
											value="soon"
											checked={state_VRGames.gameStatus === 'soon'}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="soonStatusRadio"
										/>
										<label htmlFor="soonStatusRadio" className="cursor-pointer">
											Coming Soon
										</label>
									</div>
									<div
										className={`flex items-center justify-center w-full text-nowrap border rounded-full px-3 py-1 ${state_VRGames.gameStatus === 'nothing' ? 'bg-gradient-to-br from-zinc-300 to-zinc-500 text-white' : 'text-black hover:bg-gray-200'}`}
									>
										<input
											type="radio"
											name="gameStatus"
											value="nothing"
											checked={state_VRGames.gameStatus === 'nothing'}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="nothingStatusRadio"
										/>
										<label
											htmlFor="nothingStatusRadio"
											className="cursor-pointer"
										>
											Nothing
										</label>
									</div>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between">
								<label className="w-64">Mixed Reality:</label>
								<div className="flex gap-2 items-center">
									<label>
										<input
											type="radio"
											name="mixedReality"
											value="yes"
											checked={state_VRGames.mixedReality === 'yes'}
											onChange={handleInputChange_VRGames}
											className="mr-2"
										/>
										Yes
									</label>
									<label>
										<input
											type="radio"
											name="mixedReality"
											value="no" // Gunakan nilai boolean langsung
											checked={state_VRGames.mixedReality === 'no'}
											onChange={handleInputChange_VRGames}
											className="mr-2"
										/>
										No
									</label>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Supported Quest:
									</span>
								</label>
								<div className="flex flex-row gap-2 justify-start w-full">
									<div
										className={`flex gap-2 items-center w-full text-center ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<input
											type="checkbox"
											name="supportMetaQuest3"
											checked={state_VRGames.supportMetaQuest3}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="metaQuest3Checkbox"
										/>
										<label
											htmlFor="metaQuest3Checkbox"
											className={`cursor-pointer flex items-center justify-center text-nowrap border rounded-full w-full text-center px-3 py-1 ${state_VRGames.supportMetaQuest3 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
										>
											Meta Quest 3
										</label>
									</div>
									<div
										className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<input
											type="checkbox"
											name="supportMetaQuestPro"
											checked={state_VRGames.supportMetaQuestPro}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="metaQuestProCheckbox"
										/>
										<label
											htmlFor="metaQuestProCheckbox"
											className={`cursor-pointer flex items-center text-nowrap justify-center border rounded-full w-full px-3 py-1 ${state_VRGames.supportMetaQuestPro ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
										>
											Meta Quest Pro
										</label>
									</div>
									<div
										className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<input
											type="checkbox"
											name="supportMetaQuest2"
											checked={state_VRGames.supportMetaQuest2}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="metaQuest2Checkbox"
										/>
										<label
											htmlFor="metaQuest2Checkbox"
											className={`cursor-pointer flex items-center justify-center text-nowrap border rounded-full w-full px-3 py-1 ${state_VRGames.supportMetaQuest2 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
										>
											Meta Quest 2
										</label>
									</div>
									<div
										className={`flex gap-2 items-center w-full ${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										<input
											type="checkbox"
											name="supportMetaQuest1"
											checked={state_VRGames.supportMetaQuest1}
											onChange={handleInputChange_VRGames}
											className="hidden"
											id="metaQuest1Checkbox"
										/>
										<label
											htmlFor="metaQuest1Checkbox"
											className={`cursor-pointer flex items-center justify-center text-nowrap w-full border rounded-full px-3 py-1 ${state_VRGames.supportMetaQuest1 ? 'bg-gradient-to-br from-blue-300 to-blue-500 text-white' : 'bg-gray-200 text-black'}`}
										>
											Meta Quest 1
										</label>
									</div>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="imageLink">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Image URL
									</span>
								</label>
								<input
									type="text"
									id="imageLink"
									name="imageLink"
									placeholder="Enter Image URL"
									value={imageLink === '' ? state_VRGames.photoUrl : imageLink}
									onChange={handleImageLinkChange}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="videoIdYouTube">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Video ID YouTube
									</span>
								</label>
								<input
									type="text"
									id="videoIdYouTube"
									name="videoIdYouTube"
									placeholder="Fill Here"
									value={state_VRGames.videoIdYouTube || ''}
									onChange={handleInputChange_VRGames}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<input
								className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
								type="submit"
								value={id ? 'Update' : 'Save'}
								onClick={uploadImage_VRGames}
							/>
						</>
					)}

					{dataType === 'product' && (
						<>
							<span
								className={`font-bold ${isDarkMode ? 'text-white' : 'text-black'} flex flex-col gap-2`}
							>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
								<span>Product Information</span>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
							</span>
							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productName">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Product Name
									</span>
								</label>
								<input
									type="text"
									id="productName"
									name="productName"
									placeholder="Fill Here"
									value={state_Product.productName || ''}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productPhoto">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Product Photo
									</span>
								</label>
								<input
									type="file"
									id="productPhoto"
									name="productPhoto"
									className={`${isDarkMode ? 'text-white' : 'text-black'} w-full`}
									onChange={(event) => {
										setImageUpload_Product(event.target.files[0])
									}}
								/>
							</div>

							<span
								className={`font-bold ${isDarkMode ? 'text-white' : 'text-black'} flex flex-col gap-2`}
							>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
								<span>Product Description</span>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
							</span>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productDescription_EN">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										English
									</span>
								</label>
								<input
									type="text"
									id="productDescription_EN"
									name="productDescription_EN"
									placeholder="Fill Here"
									value={state_Product.productDescription_EN || ''}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productDescription_ID">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Indonesian
									</span>
								</label>
								<input
									type="text"
									id="productDescription_ID"
									name="productDescription_ID"
									placeholder="Fill Here"
									value={state_Product.productDescription_ID || ''}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<span
								className={`font-bold ${isDarkMode ? 'text-white' : 'text-black'} flex flex-col gap-2`}
							>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
								<span>Price & Stock</span>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
							</span>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productPrice">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Price <b>(Rp)</b>
									</span>
								</label>
								<input
									type="number"
									id="productPrice"
									name="productPrice"
									placeholder="Fill Here"
									value={state_Product.productPrice || 0}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productStock">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Stock
									</span>
								</label>
								<input
									type="number"
									id="productStock"
									name="productStock"
									placeholder="Fill Here"
									value={state_Product.productStock || 0}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<span
								className={`font-bold ${isDarkMode ? 'text-white' : 'text-black'} flex flex-col gap-2`}
							>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
								<span>Product Link on Online Shop</span>
								<hr className="border-[1px] border-gray-300 opacity-40 rounded" />
							</span>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productLinkTokopedia">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Tokopedia
									</span>
								</label>
								<input
									type="url"
									id="productLinkTokopedia"
									name="productLinkTokopedia"
									placeholder="Fill Here"
									value={state_Product.productLinkTokopedia || ''}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="productLinkShopee">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Shopee
									</span>
								</label>
								<input
									type="url"
									id="productLinkShopee"
									name="productLinkShopee"
									placeholder="Fill Here"
									value={state_Product.productLinkShopee || ''}
									onChange={handleInputChange_Product}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<input
								className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
								type="submit"
								value={id ? 'Update' : 'Save'}
								onClick={uploadImage_Product}
							/>
						</>
					)}

					{dataType === 'eligibleUser' && (
						<>
							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="dataType">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										User Type:
									</span>
								</label>
								<div className="flex flex-row gap-2 justify-start w-full">
									<select
										id="dataType"
										name="dataType"
										value={
											localStorage.getItem('addUserType') || dataAddUserType
										}
										onChange={handleDataAddTypeUser}
										className={`text-black outline-none rounded-xl px-4 py-3 input-box w-full`}
									>
										<option value="standalone">Standalone</option>
										<option value="pcvr">PCVR</option>
									</select>
								</div>
							</div>

							<div className="flex gap-3 items-center justify-between w-full">
								<label className="w-64" htmlFor="email">
									<span
										className={`${isDarkMode ? 'text-white' : 'text-black'}`}
									>
										Email
									</span>
								</label>
								<input
									type="email"
									id="email"
									name="email"
									placeholder="Fill Here"
									value={state_EligibleUsers.email || ''}
									onChange={handleInputChange_EligibleUsers}
									className="rounded p-2 input-box w-full"
								/>
							</div>

							<input
								className="w-full bg-blue-500 hover:bg-blue-700 transition-all duration-300 text-white rounded-xl p-2 cursor-pointer"
								type="submit"
								value={id ? 'Update' : 'Save'}
							/>
						</>
					)}
				</form>

				<Helmet>
					<title>
						{id
							? dataType === 'vrGames'
								? 'Edit VR Games'
								: 'Edit Product'
							: dataType === 'vrGames'
								? 'Add VR Games'
								: 'Add Product'}{' '}
						| Admin
					</title>
				</Helmet>
			</div>

			<style>
				{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default AddEdit
