import React, { useReducer, useEffect, useState } from 'react'
import fireDb from '../firebase'
import { Helmet } from 'react-helmet'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'
import toast, { Toaster } from 'react-hot-toast'
import './Home.css'

const initialState = {
	data: {},
	search: '',
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return { ...state, data: action.payload }
		case 'SET_SEARCH':
			return { ...state, search: action.payload }
		default:
			return state
	}
}

const ListUsers_Admin = () => {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [newUser, setNewUser] = useState({
		email: '',
		categories: ['standalone'],
	})
	const [editingUserId, setEditingUserId] = useState(null)

	const categories = ['standalone', 'pcvr', 'qgo']

	useEffect(() => {
		const fetchData = () => {
			const allUsers = {}
			const promises = categories.map((category) =>
				fireDb.ref(`eligibleUsers/${category}`).once('value')
			)

			Promise.all(promises).then((snapshots) => {
				snapshots.forEach((snapshot, index) => {
					const category = categories[index]
					snapshot.forEach((childSnapshot) => {
						const userId = childSnapshot.key
						const userData = childSnapshot.val()

						if (!allUsers[userId]) {
							allUsers[userId] = {
								email: userData.email,
								timeAdded: userData.timeAdded,
								categories: [],
							}
						}
						allUsers[userId].categories.push(category)
					})
				})

				dispatch({ type: 'SET_DATA', payload: allUsers })
			})
		}

		fetchData()
	}, [])

	const handleSearch = (value) => {
		dispatch({ type: 'SET_SEARCH', payload: value })
	}

	const handleCategoryChange = (userId, category) => {
		const updatedUsers = { ...state.data }
		const user = updatedUsers[userId]

		if (user.categories.includes(category)) {
			user.categories = user.categories.filter((cat) => cat !== category)
			fireDb.ref(`eligibleUsers/${category}/${userId}`).remove()
		} else {
			user.categories.push(category)
			fireDb.ref(`eligibleUsers/${category}/${userId}`).set({
				email: user.email,
				timeAdded: user.timeAdded,
			})
		}

		dispatch({ type: 'SET_DATA', payload: updatedUsers })
	}

	const handleDeleteUser = (userId) => {
		if (window.confirm('Apakah Anda yakin ingin menghapus pengguna ini?')) {
			const updatedUsers = { ...state.data }
			delete updatedUsers[userId]

			categories.forEach((category) => {
				fireDb.ref(`eligibleUsers/${category}/${userId}`).remove()
			})

			dispatch({ type: 'SET_DATA', payload: updatedUsers })
			toast.success('Pengguna berhasil dihapus!')
		}
	}

	const handleAddOrEditUser = () => {
		if (!newUser.email) {
			toast.error('Mohon masukkan email pengguna!')
			return
		}

		const userId =
			editingUserId || newUser.email.split('@')[0].replace(/\./g, '')
		const userData = {
			email: newUser.email,
			timeAdded: new Date().toISOString(),
		}

		categories.forEach((category) => {
			if (newUser.categories.includes(category)) {
				fireDb.ref(`eligibleUsers/${category}/${userId}`).set(userData)
			} else {
				fireDb.ref(`eligibleUsers/${category}/${userId}`).remove()
			}
		})

		const updatedUsers = { ...state.data }
		updatedUsers[userId] = { ...userData, categories: newUser.categories }
		dispatch({ type: 'SET_DATA', payload: updatedUsers })

		setNewUser({ email: '', categories: ['standalone'] })
		setEditingUserId(null)
		toast.success(
			editingUserId
				? 'Pengguna berhasil diperbarui!'
				: 'Pengguna berhasil ditambahkan!'
		)
	}

	const filteredUsers = Object.keys(state.data).filter((userId) =>
		state.data[userId].email.toLowerCase().includes(state.search.toLowerCase())
	)

	return (
		<div className={`pt-6 px-6 mb-10 w-full min-h-screen`}>
			<Toaster />
			<Helmet>
				<title>Daftar Pengguna | HyperTopia Admin</title>
			</Helmet>
			<div className="mx-auto">
				{/* Add/Edit Form */}
				<div className="bg-white p-4 rounded-xl mb-6 border-2">
					<h2 className="text-lg font-bold mb-4">
						{/* {editingUserId ? 'Edit User' : 'Add New User'} */}
						Tambah Pengguna Baru
					</h2>
					<div className="flex items-center gap-4 mb-4">
						<input
							type="email"
							placeholder="Masukkan Email Pengguna"
							value={newUser.email}
							onChange={(e) =>
								setNewUser({ ...newUser, email: e.target.value })
							}
							className="px-4 py-2 border rounded-lg shadow-inner flex-1"
						/>
						<button
							className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
							onClick={handleAddOrEditUser}
						>
							{editingUserId ? 'Perbarui' : 'Tambah'}
						</button>
					</div>
					<div className="flex gap-4">
						{categories.map((category) => (
							<label
								key={category}
								className="flex items-center gap-2 cursor-pointer"
							>
								<input
									type="checkbox"
									checked={newUser.categories.includes(category)}
									className="appearance-none h-6 w-6 rounded-full border border-gray-300 bg-gray-200 checked:bg-blue-600 checked:border-blue-600 checked:text-white focus:ring focus:ring-blue-400"
									onChange={() =>
										setNewUser((prev) => ({
											...prev,
											categories: prev.categories.includes(category)
												? prev.categories.filter((cat) => cat !== category)
												: [...prev.categories, category],
										}))
									}
								/>
								{category.toUpperCase()}
							</label>
						))}
					</div>
				</div>

				<div className="mb-4">
					<input
						type="text"
						placeholder="Cari Pengguna..."
						value={state.search}
						onChange={(e) => handleSearch(e.target.value)}
						className="w-full px-4 py-2 rounded-lg border-2"
					/>
				</div>

				{/* Users Table */}
				<div className="overflow-x-auto rounded-2xl bg-white border-2">
					<table className="w-full table-auto border-collapse rounded-lg">
						<thead>
							<tr className="bg-[#0081FB] text-white text-left">
								<th className="py-3 px-4">No</th>
								<th className="py-3 px-4">Email</th>
								<th className="py-3 px-4">Waktu Ditambahkan</th>
								<th className="py-3 px-4">Standalone</th>
								<th className="py-3 px-4">PCVR</th>
								<th className="py-3 px-4">QGO</th>
								<th className="py-3 px-4">Aksi</th>
							</tr>
						</thead>
						<tbody>
							{filteredUsers.map((userId, index) => {
								const user = state.data[userId]
								const formattedTime = new Date(user.timeAdded).toLocaleString(
									'id-ID',
									{
										day: '2-digit',
										month: 'long',
										year: 'numeric',
										hour: '2-digit',
										minute: '2-digit',
										second: '2-digit',
										hour12: true,
									}
								)
								return (
									<tr
										key={userId}
										className={`border-b ${
											editingUserId === userId
												? 'bg-yellow-200' // Highlighted background for editing
												: index % 2 === 0
													? 'bg-gray-100'
													: 'bg-white'
										}`}
									>
										<td
											className={`py-3 px-4 ${
												editingUserId === null || editingUserId === userId
													? 'text-gray-900'
													: 'text-gray-400'
											}`}
										>
											{index + 1}
										</td>
										<td
											className={`py-3 px-4 ${
												editingUserId === null || editingUserId === userId
													? 'text-gray-900'
													: 'text-gray-400'
											}`}
										>
											{user.email}
										</td>
										<td
											className={`py-3 px-4 ${
												editingUserId === null || editingUserId === userId
													? 'text-gray-900'
													: 'text-gray-400'
											}`}
										>
											{formattedTime}
										</td>
										{categories.map((category) => (
											<td
												key={category}
												className={`py-3 px-4 text-center ${
													editingUserId === null || editingUserId === userId
														? 'text-gray-900'
														: 'text-gray-400'
												}`}
											>
												<div className="flex justify-center">
													<input
														type="checkbox"
														checked={user.categories.includes(category)}
														className="appearance-none h-6 w-6 rounded-full border border-gray-300 bg-gray-200 checked:bg-blue-600 checked:border-blue-600 checked:text-white focus:ring focus:ring-blue-400"
														onChange={() =>
															handleCategoryChange(userId, category)
														}
														disabled={editingUserId === null}
													/>
												</div>
											</td>
										))}
										<td
											className={`py-3 px-4 flex space-x-2 ${
												editingUserId === null || editingUserId === userId
													? 'text-gray-900'
													: 'text-gray-400'
											}`}
										>
											{editingUserId === userId ? (
												<></>
											) : (
												<button
													className="text-blue-500 hover:text-blue-700"
													onClick={() => {
														setEditingUserId(userId)
														setNewUser({
															email: user.email,
															categories: user.categories,
														})
													}}
												>
													<FaEdit />
												</button>
											)}
											<button
												className="text-red-500 hover:text-red-700"
												onClick={() => handleDeleteUser(userId)}
											>
												<FaTrashAlt />
											</button>
										</td>
									</tr>
								)
							})}
							{filteredUsers.length === 0 && (
								<tr>
									<td colSpan="7" className="text-center py-4 text-gray-500">
										No users found.
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default ListUsers_Admin
