import React, { useState, useEffect } from 'react'
import fireDb from '../firebase'
import { Link } from 'react-router-dom'
import bgPattern from '../assets/images/bg.jpg'
import { useDarkModeContext } from '../DarkModeContext'

import { Helmet } from 'react-helmet'
import { FaChevronRight, FaFolderOpen, FaHome } from 'react-icons/fa'
import { MdAdminPanelSettings, MdDashboard } from 'react-icons/md'
import { SiShopee } from 'react-icons/si'
import { useTitle } from '../TitleContext'

const DashboardCard = ({
	link,
	icon,
	title,
	description,
	badge1,
	badge2,
	bgPattern,
	isDarkMode,
	gridColsVRGamesMenu,
}) => {
	const { setTitle } = useTitle() // Gunakan setTitle untuk mengubah judul

	useEffect(() => {
		setTitle('Dashboard Admin') // Set judul saat halaman dimuat
	}, [setTitle])

	return (
		<Link to={link} onClick={() => setTitle(title)}>
			<div
				className={`h-80 ${
					isDarkMode ? 'bg-zinc-200 text-black' : 'bg-zinc-200'
				} rounded-2xl border-2 h-full flex flex-col justify-start items-start ${
					gridColsVRGamesMenu === 'grid-cols-1' ? 'p-6' : 'p-10'
				} gap-4 w-full`}
				style={{
					background: `linear-gradient(to top right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0.9) 65%, rgba(255, 255, 255, 0.8) 100%), url(${bgPattern}) center/cover no-repeat`,
				}}
			>
				<div
					className={`flex flex-col ${
						gridColsVRGamesMenu === 'grid-cols-1' ? 'gap-2' : 'gap-4'
					}`}
				>
					{icon}
					<span className="flex flex-col gap-1">
						<span
							className={`${
								gridColsVRGamesMenu === 'grid-cols-1' ? 'text-2xl' : 'text-3xl'
							} font-semibold flex items-center`}
						>
							{title}
							{badge1 && (
								<span className="text-white px-2 py-1 bg-teal-500 text-base font-semibold ml-2 rounded-lg inline-block">
									{badge1}
								</span>
							)}
							{badge2 && (
								<span className="text-white px-2 py-1 bg-teal-700 text-base font-semibold ml-2 rounded-lg inline-block">
									{badge2}
								</span>
							)}
						</span>
						<span
							className={`${
								gridColsVRGamesMenu === 'grid-cols-1' ? 'text-sm' : 'text-base'
							}`}
						>
							{description}
						</span>
					</span>
				</div>
			</div>
		</Link>
	)
}

const DashboardAdmin = () => {
	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')
	const { isDarkMode } = useDarkModeContext()

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 1275) {
				setGridColsVRGamesMenu('grid-cols-3')
			} else if (window.innerWidth >= 875) {
				setGridColsVRGamesMenu('grid-cols-2')
			} else {
				setGridColsVRGamesMenu('grid-cols-1')
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const cards = [
		{
			link: '/list-vr-games',
			title: 'Game VR',
			description: 'Kelola dan telusuri Game VR dalam basis data Anda.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/list-products',
			title: 'Produk',
			description: 'Kelola dan telusuri Produk di toko Anda.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/list-users',
			title: 'Pengguna',
			description: 'Kelola dan telusuri pengguna yang terdaftar.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/list-orders',
			title: 'Pesanan',
			description: 'Kelola dan telusuri pesanan pengguna.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/list-shopee-orders',
			title: 'Pesanan Shopee',
			description: 'Kelola dan telusuri pesanan dari Shopee.',
			icon: (
				<SiShopee
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-orange-600"
				/>
			),
		},
		{
			link: '/list-requests',
			title: 'Permintaan',
			description: 'Kelola dan telusuri permintaan pengguna.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/upload-cover',
			title: 'Unggah Sampul',
			description: 'Unggah gambar sampul untuk game dan produk.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/download-games-cover',
			title: 'Unduh Sampul Game',
			description: 'Unduh sampul untuk Game VR.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/download-products-cover',
			title: 'Unduh Sampul Produk',
			description: 'Unduh sampul untuk produk.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/upload-to-drive',
			title: 'Unggah ke Google Drive',
			description: 'Unggah file langsung ke Google Drive.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
		{
			link: '/firmware-quest',
			title: 'Firmware Quest',
			description: 'Kelola pembaruan firmware untuk perangkat VR Quest.',
			icon: (
				<FaFolderOpen
					size={gridColsVRGamesMenu === 'grid-cols-1' ? 40 : 60}
					className="text-[#0081FB]"
				/>
			),
		},
	]

	return (
		<div className={`pt-5 mx-5 pb-5 min-h-screen`}>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
				{cards.map((card, index) => (
					<DashboardCard
						key={index}
						link={card.link}
						title={card.title}
						icon={card.icon}
						description={card.description}
						bgPattern={bgPattern}
						isDarkMode={isDarkMode}
						gridColsVRGamesMenu={gridColsVRGamesMenu}
					/>
				))}
			</div>
			<Helmet>
				<title>Dasbor Admin | HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default DashboardAdmin
