import React, { useState, useEffect } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom'
import { DarkModeProvider } from './DarkModeContext'
import DashboardAdmin from './pages/DashboardAdmin'
import AddEdit from './pages/AddEdit'
import SignIn from './pages/SignIn'
import ListProducts_Admin from './pages/ListProducts_Admin'
import ListVRGames_Admin from './pages/ListVRGames_Admin'
import { auth } from './firebase'
import ListUsers_Admin from './pages/ListUsers_Admin'
import bgPage from './assets/images/bg.jpg'
import { useDarkModeContext } from './DarkModeContext'
import RequestVRGames from './pages/VR Games/RequestVRGames'
import ErrorPage from './pages/ErrorPage'
import ListRequests_Admin from './pages/ListRequests_Admin'
import ListOrders_Admin from './pages/ListOrders_Admin'
import UploadCover_Admin from './pages/UploadCover_Admin'
import { useNetworkState } from 'react-use'
import toast, { Toaster } from 'react-hot-toast'
import DownloadGamesCover_Admin from './pages/DownloadGamesCover_Admin'
import DownloadProductsCover_Admin from './pages/DownloadProductsCover_Admin'
import AddEditEligibleUsers from './pages/AddEdit_EligibleUsers'
import AddEdit_StandaloneGames from './pages/AddEdit_StandaloneGames'
import AddEdit_PCVRGames from './pages/AddEdit_PCVRGames'
import AddEdit_Standalone_EligibleUsers from './pages/AddEdit_Standalone_EligibleUsers'
import AddEdit_PCVR_EligibleUsers from './pages/AddEdit_PCVR_EligibleUsers'
import UploadToDrive from './pages/UploadToDrive'
import DownloadTest from './pages/DownloadTesting'
import FirmwareInputPage from './pages/AddEdit_FirmwareQuest'
import FirmwareListPage from './pages/FirmwareListPage'
import Breadcrumbs from './components/Breadcrumbs'
import { TitleProvider } from './TitleContext'
import Sidebar from './components/Sidebar'
import ListShopeeOrders_Admin from './pages/ListShopeeOrders_Admin'
import AddEdit_ShopeeOrders from './pages/AddEdit_ShopeeOrders'

const App = () => {
	return (
		<DarkModeProvider>
			<TitleProvider>
				<Router>
					<Routes>
						<Route path="/*" element={<AppLayout />} />
					</Routes>
				</Router>
			</TitleProvider>
		</DarkModeProvider>
	)
}

const AppLayout = () => {
	const { isDarkMode } = useDarkModeContext()
	const location = useLocation()
	const navigate = useNavigate()

	const [user, setUser] = useState(null)
	const [showLoginModal, setShowLoginModal] = useState(false)

	const [isDisconnected, setIsDisconnected] = useState(false)
	const networkState = useNetworkState()

	useEffect(() => {
		setIsDisconnected(!networkState.online)
	}, [])

	useEffect(() => {
		if (!networkState.online && !isDisconnected) {
			toast.error('Koneksi internet bermasalah, harap periksa koneksi Anda.')
			setIsDisconnected(true)
		}

		if (networkState.online && isDisconnected) {
			toast.success('Koneksi internet telah terhubung kembali.')
			setIsDisconnected(false)
		}
	}, [networkState.online])

	useEffect(() => {
		if (!localStorage.getItem('dataSaver')) {
			localStorage.setItem('dataSaver', true)
		}
	}, [])

	// useEffect(() => {
	// 	const unsubscribe = auth.onAuthStateChanged((user) => {
	// 		if (user) {
	// 			setUser(user)
	// 			setShowLoginModal(false) // Tutup modal jika user login
	// 		} else {
	// 			setUser(null)
	// 			if (location.pathname !== '/signin') {
	// 				setShowLoginModal(true) // Tampilkan modal jika belum login
	// 			}
	// 		}
	// 	})

	// 	return () => {
	// 		unsubscribe()
	// 	}
	// }, [location.pathname])

	return (
		<div className="relative min-h-screen">
			{/* Konten utama dengan efek blur */}
			<div className="flex flex-row h-full">
				{/* <Sidebar /> */}
				<div
					className={`${
						showLoginModal && location.pathname !== '/signin' ? 'blur-md' : ''
					} min-h-screen bg-repeat flex flex-col bg-white w-full`}
				>
					<Toaster />
					<Breadcrumbs />

					<Routes>
						<Route path="/signin" element={<SignIn />} />
						<Route path="/" element={<DashboardAdmin />} />
						<Route path="/list-vr-games" element={<ListVRGames_Admin />} />
						<Route
							path="/list-vr-games/standalone/add"
							element={<AddEdit_StandaloneGames />}
						/>
						<Route
							path="/list-vr-games/standalone/update/:id"
							element={<AddEdit_StandaloneGames />}
						/>
						<Route
							path="/list-vr-games/pcvr/add"
							element={<AddEdit_PCVRGames />}
						/>
						<Route
							path="/list-vr-games/pcvr/update/:id"
							element={<AddEdit_PCVRGames />}
						/>
						<Route path="/list-products" element={<ListProducts_Admin />} />
						<Route path="/list-users" element={<ListUsers_Admin />} />
						<Route
							path="/list-users/standalone/add"
							element={<AddEdit_Standalone_EligibleUsers />}
						/>
						<Route
							path="/list-users/standalone/update/:id"
							element={<AddEdit_Standalone_EligibleUsers />}
						/>
						<Route
							path="/list-users/pcvr/add"
							element={<AddEdit_PCVR_EligibleUsers />}
						/>
						<Route
							path="/list-users/pcvr/update/:id"
							element={<AddEdit_PCVR_EligibleUsers />}
						/>
						<Route path="/list-users/add" element={<AddEditEligibleUsers />} />
						<Route
							path="/list-shopee-orders/add"
							element={<AddEdit_ShopeeOrders />}
						/>
						<Route
							path="/list-shopee-orders/update/:id"
							element={<AddEdit_ShopeeOrders />}
						/>
						<Route path="/list-requests" element={<ListRequests_Admin />} />
						<Route path="/list-requests/add" element={<RequestVRGames />} />
						<Route
							path="/list-requests/update/:id"
							element={<RequestVRGames />}
						/>
						<Route path="/list-orders" element={<ListOrders_Admin />} />
						<Route
							path="/list-shopee-orders"
							element={<ListShopeeOrders_Admin />}
						/>
						<Route path="/upload-cover" element={<UploadCover_Admin />} />
						<Route
							path="/download-games-cover"
							element={<DownloadGamesCover_Admin />}
						/>
						<Route
							path="/download-products-cover"
							element={<DownloadProductsCover_Admin />}
						/>
						<Route path="/upload-to-drive" element={<UploadToDrive />} />
						<Route path="/firmware-quest" element={<FirmwareListPage />} />
						<Route path="/firmware-quest/add" element={<FirmwareInputPage />} />
						<Route
							path="/firmware-quest/update/:id"
							element={<FirmwareInputPage />}
						/>
						<Route path="/download-test" element={<DownloadTest />} />
						<Route path="/add" element={<AddEdit />} />
						<Route path="/update/:id" element={<AddEdit />} />
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				</div>
			</div>

			{showLoginModal && location.pathname !== '/signin' && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
					<div className="bg-white rounded-lg p-8 shadow-lg text-center">
						<h2 className="text-xl font-bold mb-4">Anda belum login</h2>
						<p className="text-gray-600 mb-6">
							Silakan login terlebih dahulu untuk mengakses halaman ini.
						</p>
						<button
							onClick={() => navigate('/signin')}
							className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
						>
							Login Sekarang
						</button>
					</div>
				</div>
			)}

			<style>
				{`
				  /* Style untuk scrollbar */
        ::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
        }

        /* Track scrollbar */
        ::-webkit-scrollbar-track {
          border-radius: 10px; /* Border radius untuk track */
        }

        /* Handle scrollbar */
        ::-webkit-scrollbar-thumb {
          background-color: #888; /* Warna handle */
          border-radius: 10px; /* Border radius untuk handle */
        }

        /* Ketika hover pada handle */
        ::-webkit-scrollbar-thumb:hover {
          background-color: #555; /* Warna handle saat hover */
        }

        .blur-md {
          filter: blur(8px);
        }
        `}
			</style>
		</div>
	)
}

export default App
